import { ButtonVariantKey, ButtonVariantValue, ButtonWidthKey } from '@belong/types';

// TBC: determine the usage of this enum. this and variant type used interchangeably
export const BUTTON_VARIANTS: Record<ButtonVariantKey, ButtonVariantValue> = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary'
};

export const BUTTON_WIDTHS: Record<ButtonWidthKey, ButtonWidthKey> = {
  default: 'default',
  full: 'full',
  half: 'half',
  min: 'min'
};
