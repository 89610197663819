import type {
  IAuth,
  IErrorResponse,
  IHeaders,
  IInternetServiceDetail,
  IInternetServiceDetailAPI,
  IInternetServiceActionAPI,
  IInternetServiceAddress,
  IInflightOrderChangePlan,
  IInflightOrder,
  IInflightOrderDisconnect
} from '@belong/types';
import { HEADERS } from '@belong/constants';
import { getAuthCookie } from '@belong/cookies';
import { getCorrelationId } from '@belong/user';

export const DEFAULT_ERROR: IErrorResponse = {
  errorCode: 'DEFAULT',
  errorDescription: 'Something went wrong',
  status: '500'
};

export const INVALID_REQUEST_PARAMETER_ERROR: IErrorResponse = {
  errorCode: 'INVALID_REQUEST_PARAMETER',
  errorDescription: 'Invalid serviceId parameter',
  status: '400'
};

export const isError = <T>(response: T | IErrorResponse): response is IErrorResponse => {
  if (!response) {
    return false;
  }
  const hasNetworkError = Number((response as IErrorResponse).status) > 399;
  const hasErrorPayload = (response as IErrorResponse).errorCode !== undefined;
  return hasNetworkError || hasErrorPayload;
};

export const getAPIError = (error: Partial<IErrorResponse>): IErrorResponse => {
  if (!error.errorCode) {
    return DEFAULT_ERROR;
  }
  return error as IErrorResponse;
};

export const createAbortController = (): AbortController | null =>
  typeof window !== 'undefined' ? new AbortController() : null;

export const isUnauthorisedStatus = (status: number): boolean => status === 401;

/**
 * Get the required session info to make the request
 * On the client: pull credentials from the cookie
 * On the server: expect credentials to be supplied in the headers param
 */
export const getSessionInfo = (headers?: IHeaders): IAuth => {
  const {
    [HEADERS.CORRELATION_ID]: correlationId,
    [HEADERS.ACCESS_TOKEN]: accessToken,
    [HEADERS.ID_TOKEN]: idToken
  } = headers || {};
  const cookie = getAuthCookie();

  return {
    accessToken: accessToken || cookie.accessToken || '',
    clientId: cookie.clientId,
    correlationId: correlationId || getCorrelationId(),
    expiresIn: cookie.expiresIn,
    idToken: idToken || cookie?.idToken || '',
    tokenType: cookie.tokenType
  };
};

const isNbnActionAvailable =
  (codeToMatch: IInternetServiceActionAPI['code']) =>
  ({ available, code }: IInternetServiceActionAPI): boolean =>
    code === codeToMatch && available;

export const getNbnActions = (actions: IInternetServiceActionAPI[]): IInternetServiceDetail['actions'] => ({
  changePlan: actions.some(isNbnActionAvailable('CHANGE_PLAN')),
  disconnect: actions.some(isNbnActionAvailable('DISCONNECT')),
  move: actions.some(isNbnActionAvailable('MOVE')),
  troubleshooting: actions.some(isNbnActionAvailable('TROUBLESHOOT'))
});

export const getNbnAddress = (addresses: IInternetServiceAddress[] = []): IInternetServiceDetail['addresses'] => ({
  billing: addresses.find(({ premiseType }) => premiseType === 'BILLING'),
  service: addresses.find(({ premiseType }) => premiseType === 'SERVICE')
});

const isOrderChangePlan = (order?: IInflightOrder): order is IInflightOrderChangePlan =>
  order?.orderType === 'CHANGE_PLAN';
const isOrderDisconnect = (order?: IInflightOrder): order is IInflightOrderDisconnect =>
  order?.orderType === 'DISCONNECT';

export const getNbnInflightOrders = (
  orders?: IInternetServiceDetailAPI['inFlightOrders']
): IInternetServiceDetail['inFlightOrders'] => ({
  changePlan: orders?.find(isOrderChangePlan),
  disconnect: orders?.find(isOrderDisconnect)
});
