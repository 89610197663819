import { useState } from 'react';

/**
 * A hook that returns a value that is only calculated once.
 * Pass in the factory function that will return the value.
 * Nobody can update the value so its identity stays stable over the lifetime
 * of the calling component.
 *
 * This is different to `useRef()` because it will only calculate the value once.
 */
export const useReadOnly = <T = any,>(factoryFn: () => T): T => {
  const [value] = useState(factoryFn);
  return value;
};
