import { logger } from '@belong/logging';
import { getConfig } from '@belong/configs/next/config';
import { useLockBodyScroll } from '@belong/react-hooks';
import { useShouldRenderChat, useStateFromChildFrame } from './hooks';

export const IFRAMER_ID = 'belong-chat-iframer';

/**
 * Renders the iframe for displaying the LiveChat within another app.
 */
export const ChatIframer = () => {
  const src = getConfig().publicRuntimeConfig.liveChat?.chatPageLocation;
  const shouldRender = useShouldRenderChat() && !!src;
  const sharedState = useStateFromChildFrame();
  useLockBodyScroll(sharedState?.isCovering);

  if (!src) {
    logger.warn('ChatIframer page location not provided');
  }

  if (!shouldRender) {
    return null;
  }

  return (
    <iframe
      title="Live chat"
      id={IFRAMER_ID}
      src={src}
      style={{
        border: 'none',
        position: 'fixed',
        height: 0,
        bottom: 0,
        right: 0,
        ...sharedState?.iframeStyles
      }}
      loading="lazy"
      sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups"
    />
  );
};

ChatIframer.displayName = 'ChatIframer';
