import { getConfig } from '@belong/configs/next/config';
import { IRouteDefinition, IRouteMatch } from '@belong/types';

/**
 * NewRelic logging utils
 * Note: Ensure the newrelic package is ONLY imported on the server to avoid
 *       NextJS build errors
 */

/**
 * This is a duplication of
 * import { matchRoute } from '@belong/url-utils', to avoid circular dependency
 */
const matchRoute = (path = '', routes: IRouteDefinition[]): IRouteMatch | null => {
  // remove hash and search before comparisons
  const cleanedPath = path.replace(/[?#].*$/, '');
  const route = routes.find(({ pattern }) => !!pattern.match(cleanedPath));
  if (!route) {
    return null;
  }

  const match = route.pattern.match(cleanedPath);
  return {
    pathname: route.path.replace('*', match._),
    spec: route.path,
    match
  };
};

// Create script injector for browser activity monitoring (only used in _document)
export function getClientMonitorScript(): string {
  const { analytic } = getConfig().publicRuntimeConfig;
  if (analytic?.newRelicEnabled && typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    return require('newrelic').getBrowserTimingHeader({
      hasToRemoveScriptWrapper: true
    });
  }
  return '';
}

// Send an error to NewRelic
export function logError(error: Error): void {
  if (!error) {
    return;
  }

  if (typeof window === 'undefined') {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
      require('newrelic').noticeError(error);
    } catch (e) {
      //
    }
  } else {
    (window as any).newrelic?.noticeError(error);
  }
}

export function sanitizePath(nextUrl: string, appRoutes: IRouteDefinition[]): string {
  const [oldPath, oldParams = ''] = nextUrl.split('?');
  const newPath = matchRoute(oldPath, appRoutes)?.pathname.replace(/\/:([^/]+)/g, '/*') || oldPath;

  const newParams = Array.from(new URLSearchParams(oldParams).entries())
    .reduce((acc, [key, val]) => {
      return `${acc}${key}=${sanitizePath(decodeURIComponent(val), appRoutes)}&`;
    }, '?')
    .slice(0, -1);

  return `${newPath}${newParams}`;
}

// NewRelic not picking up NextJS client route changes so need to send manually
// https://docs.newrelic.com/docs/browser/single-page-app-monitoring/troubleshooting/missing-route-changes-spa-agent/
export function logClientRouteChange(nextUrl: string, appRoutes: IRouteDefinition[]): void {
  const interaction = (window as any).newrelic?.interaction();
  if (interaction) {
    interaction.setName(sanitizePath(nextUrl, appRoutes));
    interaction.save();
  }
}

/**
 * Sending a custom page action, this must include a tag so we can easily filter it in new relic
 * @external https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/addpageaction/
 */
export function sendCustomNREvent(tag: string, action: string, attributes?: Record<string, any>): void {
  const { addPageAction } = (window as any).newrelic ?? {};
  if (addPageAction) {
    addPageAction(action, { ...attributes, tag });
  }
}
