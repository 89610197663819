import { FONT_COLOURS, GRADIENTS, media, isFocused, BORDER_RADIUS, BUTTON_VARIANTS } from '@belong/themes';
import styled from 'styled-components';
import { Button, RichText, Copy, Inline } from '@belong/ui-components';

export const HEIGHT = 72;
export const HEIGHT_WITH_UNIT = `${HEIGHT}px`;
export const ITEM_HEIGHT = '5.6rem';

export const Flex = styled.div`
  display: flex;
`;

export const FlexLogoSpacer = styled.div`
  // match exact size of belong logo
  width: 19.8rem;
  max-width: 19.8rem;
`;

export const FlexColumn = styled.div`
  width: 25%;
  max-width: 30rem;
  // padding must match HeaderBarLink
  padding: 0 1rem;
  ${media('xl')`
    padding: 0 2.4rem;
  `}
`;

export const ProfileMenuFlex = styled.div`
  display: flex;
  justify-content: end;

  nav {
    width: 100%;
  }
`;

export const MenuList = styled.ul`
  list-style: none;

  li {
    padding: 0.4rem 0;
  }
`;

export const ProfileMenuColumn = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-right: 0;
`;

export const AccentStrip = styled.div`
  height: 0.8rem;
  background: ${GRADIENTS.ACCENT_STRIP_GLOBAL_HEADER};
`;

export const SummaryContainer = styled.div`
  border: none;
  outline: none;
  border-radius: ${BORDER_RADIUS.CARD};

  &:focus {
    ${isFocused()};
    outline-offset: 1rem; // override isFocused outline-offset
  }
`;

export const FlexNavGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const Summary = styled(RichText).attrs({
  hasColor: FONT_COLOURS.LIGHT
})``;

export const SummaryCopy = styled(Copy).attrs({ isBold: true, variant: 'large' })`
  margin-top: 1.2rem;
`;

export const SummaryButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.TERTIARY,
  isLightColor: true
})``;

export const HelpText = styled(RichText).attrs({
  contentLength: 'short',
  hasColor: FONT_COLOURS.LIGHT,
  p: 'copySmall'
})``;

export const MiddleColumnWrapper = styled(Inline)`
  > :not(:first-child):nth-child(odd) {
    margin-top: 4rem;
  }
`;
