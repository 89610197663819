import { BUTTON_VARIANTS, COLOURS, FLAIR_COLOURS } from '@belong/themes';
import styled, { css } from 'styled-components';
import { isFlairColor } from '@belong/themes/helpers/value';
import { Heading, Button } from '@belong/ui-components';

export const LinkPanelHeading = styled(Heading).attrs({ variant: 'xLarge', as: 'h2' })`
  color: ${COLOURS.BELONG_BLUE};
  padding: 0 5.6rem 0 3.2rem;
`;

export const SummaryButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.TERTIARY,
  isLightColor: true
})`
  margin: 0 3rem;
  padding: 0 0 1.2rem 0;
`;

export const LinkGroupHeading = styled(Heading).attrs({ variant: 'medium', as: 'h3' })`
  ${({ headingColor }) => {
    if (isFlairColor(headingColor)) {
      return css`
        background-image: ${FLAIR_COLOURS[headingColor]};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        display: initial;
      `;
    }
    return css`
      color: ${COLOURS.BELONG_BLUE};
    `;
  }};

  font-weight: 600;
  padding: 0 3.2rem;
`;

export const LinkGroup = styled.ul`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }

  li a {
    height: 5rem;
    min-height: 5rem;
  }
`;
