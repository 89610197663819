import { IDictionary } from '@belong/types';
import isFunction from 'lodash/isFunction';
import { isObject, isString } from '../object';

export interface IStrictDictionary {
  [key: string]: string | number | boolean;
}

export interface IOptionalStringMap {
  [key: string]: string | null | undefined;
}

export interface IStringMap {
  [key: string]: string;
}

export const toCamelCase = (str = ''): string => {
  return str
    .replace(/-/, ' ')
    .replace(/\s(.)/g, (s: string) => s.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, (s: string) => s.toLowerCase());
};

export const toSentenceCase = (str = ''): string => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;

export const getCurrencyStringFromPrice = (price: any): string => {
  if (price) {
    const { unit, value } = price;
    if (!unit || typeof value !== 'number') {
      return '';
    }

    return `${unit}${value}`;
  }

  return '';
};

type DictionaryInterpolator = (str: string) => string;
const isDictionaryInterpolator = (value: any): value is DictionaryInterpolator => isFunction(value);

/**
 * Create an interpolator function that injects values into a given string
 * Empty dictionary values are converted to ''
 * Number dictionary values are converted to string
 */
export const createDictionaryInterpolator = (dictionary: IDictionary, regExp?: RegExp): DictionaryInterpolator => {
  const regex = regExp || new RegExp(`{{(${Object.keys(dictionary).join('|')})}}`, 'gi');
  return (str = ''): string => {
    return str.replace(regex, (_, match) => {
      return (dictionary[match] ?? '').toString();
    });
  };
};

/**
 * Apply dictionary entries to a string
 */
export const applyDictionary = (dictionary: IDictionary, value = ''): string => {
  return createDictionaryInterpolator(dictionary)(value);
};

/**
 * Apply dictionary to object containing strings
 * - Null dictionary values are converted to ''
 * - Undefined dictionary values are converted to ''
 * - Number dictionary values are converted to string
 * - Boolean dictionary values are converted to string
 */
export const applyDictionaryToObject = (dictionary: IDictionary, values: IOptionalStringMap): IStringMap => {
  const injectValues = createDictionaryInterpolator(dictionary);
  // optional values from contentful are coming back as `undefined`, handling falsy values
  return Object.entries(values).reduce(
    (obj, [token, val]) => ({
      ...obj,
      [token]: injectValues(val || '')
    }),
    {}
  );
};

export const applyDictionaryRecursively = (dictionary: IDictionary | DictionaryInterpolator, value: any): any => {
  const injectValues = isDictionaryInterpolator(dictionary) ? dictionary : createDictionaryInterpolator(dictionary);
  if (isString(value)) {
    return injectValues(value);
  }
  if (Array.isArray(value)) {
    return value.map(arrItem => applyDictionaryRecursively(injectValues, arrItem));
  }
  if (isObject(value)) {
    return Object.entries(value).reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: applyDictionaryRecursively(injectValues, val)
      }),
      {}
    );
  }
  return value;
};

/**
 * Mask a string by replacing all chars with asterisks except first and last
 * - removes any whitespace from either end of the string to ensure valid visible chars
 * - specify a pad amount to increase the preserved characters on either side
 * @example
 * hello => h***o
 * hello, 2 => he*lo
 */
export const maskString = (str: string, pad?: number): string =>
  (str || '').trim().replace(/./g, (char, idx, arr) => {
    return idx < (pad || 1) || idx >= arr.length - (pad || 1) ? char : '*';
  });

/**
 * Mask an email address for privacy when logging
 * - preserves domain suffix
 * - preserves first and last char of each segment around the @
 * - preserves string lengths
 * @example test@example.com => t**t@e*****e.com
 */
export const maskEmail = (email: string): string => {
  if (!email) {
    return '';
  }

  const suffix = (email.match(/\.(?=[^.]+$).*/) || [])[0] || '';
  const result = email.replace(suffix, '').split('@').map(maskString).join('@');
  return `${result}${suffix}`;
};

export const maskToken = (token: string): string => maskString(token, 5).replace(/\*+/, '*'.repeat(10));

/**
 * Preserve length and mask all but last 3 digits.
 * @example +61488888888 => *********888
 */
export const maskMobileNumber = (number: string): string => number.replace(/.(?=...)/g, '*');

const escapeRegExp = (string: string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

/**
 * @see https://stackoverflow.com/a/1144788
 * @TODO: Remove this when `String.prototype.replaceAll` is available
 */
export const replaceAll = (str: string, find: string, replace: string): string => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

export const pluralise = (count: number, string: string, suffix = 's'): string =>
  count === 1 ? string : `${string}${suffix}`;

/**
 * Slices a string without cutting words, ensuring the result is no longer than maxLength.
 * If the string is truncated, it will cut at the last space before maxLength to avoid cutting words.
 * @example
 * // Returns "this is an"
 * sliceWithoutCuttingWords("this is an example", 10);
 *
 */
export const sliceWithoutCuttingWords = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) {
    return str;
  }
  let slicedString = str.slice(0, maxLength);

  const lastSpaceIndex = slicedString.lastIndexOf(' ');
  if (lastSpaceIndex > -1) {
    slicedString = slicedString.slice(0, lastSpaceIndex);
  }
  return slicedString;
};
