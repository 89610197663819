import { FC } from 'react';
import Head from 'next/head';

import { logger } from '@belong/logging';
import { useAutoFocusHeading } from '@belong/accessibility';

import Section, { ISection } from '../../styles/Section';
import { Copy, Heading } from '../../styles/Typography';
import { Stack } from '../../ui';
import ErrorGraphic from './assets/errorGraphic';
import { ERROR_PAGE_CONTENT, ERROR_PAGE_META } from './ErrorPageContent.data';

interface IErrorPage extends ISection {
  autoFocusHeading?: boolean;
  statusCode?: number;
}

export const TEST_ID = {
  section: 'SectionErrorPage',
  title: 'ErrorPage__title',
  message: 'ErrorPage__message'
};

const ErrorPageMeta: FC<{ statusCode: number }> = ({ statusCode }) => {
  return (
    <Head>
      <title key="title">{`${statusCode} ${ERROR_PAGE_META.pageTitle}`}</title>
      <meta name="description" key="description" content={ERROR_PAGE_META.description} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="keywords" key="keywords" content={ERROR_PAGE_META.keywords} />
    </Head>
  );
};

ErrorPageMeta.displayName = 'ErrorPageMeta';
/**
 * Generic content to be shown on a 404 or 500 page across all apps
 */
export const ErrorPageContent: FC<IErrorPage> = ({ statusCode = 404, autoFocusHeading, ...sectionProps }) => {
  logger.debug('ErrorPageContent', { statusCode });
  const content = ERROR_PAGE_CONTENT[statusCode || 404] || ERROR_PAGE_CONTENT[404];

  useAutoFocusHeading({ selector: 'h1', shouldFocus: autoFocusHeading });

  return (
    <Section data-testid={TEST_ID.section} hasPaddingTop hasPaddingBottom {...sectionProps}>
      <ErrorPageMeta statusCode={statusCode} />
      <Stack space="large" alignX="center">
        <ErrorGraphic />
        <Stack space="medium" alignX="center">
          <Heading tabIndex={-1} variant="xSmall" as="h1" data-testid={TEST_ID.title}>
            {content.title}
          </Heading>
          <Copy data-testid={TEST_ID.message}>{content.message}</Copy>
        </Stack>
      </Stack>
    </Section>
  );
};
ErrorPageContent.displayName = 'ErrorPageContent';
