import type { ITheme } from '@belong/types';

import { THEME_VARIATIONS } from './theme-variations';
import { BREAKPOINTS, FONT_SIZE, LETTER_SPACING, LINE_HEIGHT, SPACES, cssUnit } from './helpers';

export * from './helpers';
export * from './GlobalStyles';
export * from './theme-variations';

// include the light theme as a default within the THEME
export const THEME: ITheme = {
  breakpoints: { ...BREAKPOINTS },

  // Fonts
  fontSize: FONT_SIZE,
  lineHeight: LINE_HEIGHT,
  letterSpacing: LETTER_SPACING,

  spaces: SPACES,
  cssUnit,
  ...THEME_VARIATIONS.Light
};
