import { FC } from 'react';

import { ICON_NAMES } from '@belong/types';
import { FONT_COLOURS } from '@belong/themes';
import { Copy } from '@belong/ui-components';

import * as styles from './styles';

export interface IGHLargeLinkProps {
  'aria-current'?: any;
  'data-testid'?: string;
  href?: string;
  iconName?: ICON_NAMES;
  iconRight?: boolean;
  label: string;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  secondLevelLink?: boolean;
}

const getColour = ({
  href,
  onClick,
  'aria-current': ariaCurrent
}: Pick<IGHLargeLinkProps, 'href' | 'onClick' | 'aria-current'>) => {
  const isLink = !!href || !!onClick;
  if (!isLink) {
    return FONT_COLOURS.DISABLED;
  }
  if (ariaCurrent) {
    return FONT_COLOURS.ACTIVE;
  }
  return FONT_COLOURS.LIGHT;
};

export const GHMobileLink: FC<IGHLargeLinkProps> = ({
  'aria-current': ariaCurrent,
  href,
  iconName,
  iconRight = false,
  label,
  onClick,
  onKeyDown,
  ...wrapperProps
}: IGHLargeLinkProps) => {
  const colour = getColour({ 'aria-current': ariaCurrent, href, onClick });

  if (href) {
    return (
      <styles.Wrapper href={href} onClick={onClick} aria-current={ariaCurrent} onKeyDown={onKeyDown} {...wrapperProps}>
        {iconName && <styles.StyledIcon name={iconName} hasColor={colour} iconRight={iconRight} />}
        <Copy hasColor={colour} as="span">
          {label}
        </Copy>
      </styles.Wrapper>
    );
  }

  return (
    <styles.StyledButton
      onClick={onClick}
      aria-current={ariaCurrent}
      secondLevelLink={!!wrapperProps.secondLevelLink}
      aria-expanded="false"
      {...wrapperProps}
    >
      {iconName && <styles.StyledIcon name={iconName} hasColor={colour} iconRight={iconRight} />}
      <Copy hasColor={colour} as="span">
        {label}
      </Copy>
    </styles.StyledButton>
  );
};

GHMobileLink.displayName = 'GHMobileLink';
