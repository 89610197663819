import { COLOURS } from '@belong/themes';
import { Inline } from '../../ui';
import { Copy } from '../../styles/Typography';

interface ICountdownSegment {
  unit: string;
  value: string;
  showDivider?: boolean;
}

export const CountdownSegment: React.FC<ICountdownSegment> = ({ unit, value, showDivider }) => {
  return (
    <Inline space="xsmall">
      <Copy variant="large" hasColor={COLOURS.WHITE} isBold>
        {value}
      </Copy>
      <Copy variant="large" hasColor={COLOURS.BELONG_BLUE} isBold>
        {unit}
      </Copy>
      {showDivider && (
        <Copy variant="large" hasColor={COLOURS.BELONG_BLUE} isBold>
          :
        </Copy>
      )}
    </Inline>
  );
};

CountdownSegment.displayName = 'CountdownSegment';
