import { FC } from 'react';
import { COLOURS } from '@belong/themes';
import { Inline, Stack } from '../../ui';
import { Copy, VisuallyHidden } from '../../styles/Typography';
import { useCountdownTimer } from './useCountdownTimer';
import { CountdownSegment } from './CountdownSegment';
import { ICountdownTimerProps, TUnits } from './types';

export const SCREEN_READER_CONTENT = 'countdown-timer-hidden-text';
export const UNITS: Record<TUnits, string> = {
  days: 'days',
  hours: 'hours',
  minutes: 'mins'
};

export const SCREEN_READER_UNITS: Record<TUnits, string> = {
  days: 'days',
  hours: 'hours',
  minutes: 'minutes'
};

/**
 * CountdownTimer component.
 * Displays a countdown timer with days, hours, and minutes remaining until the specified end time.
 *
 * @param countdownTitle - The title of the countdown timer.
 * @param countdownEnd - The end time of the countdown timer.
 *
 * @returns A React component that displays the countdown timer.
 */

export const CountdownTimer: FC<ICountdownTimerProps> = ({ countdownTitle, countdownEnd }) => {
  const { isError, isCountdownFinished, formattedValues } = useCountdownTimer(countdownEnd);

  if (isCountdownFinished || isError) {
    return null;
  }

  return (
    <Stack space="xsmall" data-testid="countdown-timer">
      {countdownTitle && (
        <Copy variant="medium" hasColor={COLOURS.WHITE}>
          {countdownTitle}
        </Copy>
      )}

      <time>
        <VisuallyHidden data-testid={SCREEN_READER_CONTENT}>
          {Number(formattedValues.days)} {SCREEN_READER_UNITS.days}, {Number(formattedValues.hours)}{' '}
          {SCREEN_READER_UNITS.hours}, {Number(formattedValues.minutes)} {SCREEN_READER_UNITS.minutes}
        </VisuallyHidden>
        <div aria-hidden>
          <Inline space="xsmall">
            <CountdownSegment unit={UNITS.days} value={formattedValues.days} showDivider />
            <CountdownSegment unit={UNITS.hours} value={formattedValues.hours} showDivider />
            <CountdownSegment unit={UNITS.minutes} value={formattedValues.minutes} />
          </Inline>
        </div>
      </time>
    </Stack>
  );
};

CountdownTimer.displayName = 'CountdownTimer';
