import { DirectionKey, DirectionValue, FlexAlignValue, XAlignKey, XAlignValue, YAlignKey } from '@belong/types';

export const DIRECTION: Record<DirectionKey, DirectionValue> = {
  row: 'row',
  column: 'column',
  'row-reverse': 'row-reverse',
  'column-reverse': 'column-reverse'
};

export const ALIGNMENT: Record<XAlignKey, XAlignValue> = {
  left: 'left',
  center: 'center',
  right: 'right'
};

export const FLEX_ALIGN: Record<XAlignKey, FlexAlignValue> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

export const FLEX_ALIGN_REVERSE: Record<XAlignKey, FlexAlignValue> = {
  left: 'flex-end',
  center: 'center',
  right: 'flex-start'
};

export const FLEX_ALIGN_VERTICAL: Record<YAlignKey, FlexAlignValue> = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end'
};
