import kebabCase from 'lodash/kebabCase';
import { Stack } from '@belong/ui-components';

import * as styles from './styles';
import { GHMobileLink } from '../GHMobileLink';
import { IDropdownLinkGroup } from '../../GlobalHeaderDesktop/HeaderDropdown/DropdownLinkGroup';
import { ARIA_CURRENT } from '../../variables';
import { GLOBAL_HEADER_TEST_ID } from '../../testids';

export const MobileLinkGroup = ({ navLinkGroup, currentUrl, onLinkClick }: IDropdownLinkGroup): JSX.Element => {
  return (
    <nav aria-label={navLinkGroup.label}>
      <Stack space="small">
        <styles.LinkGroupHeading headingColor={navLinkGroup.labelColour}>{navLinkGroup.label}</styles.LinkGroupHeading>
        <styles.LinkGroup>
          {navLinkGroup.navLinks.map(navLink => {
            return (
              <li key={kebabCase(navLink.label).toLowerCase()}>
                <GHMobileLink
                  aria-current={currentUrl === navLink.href ? ARIA_CURRENT : undefined}
                  href={navLink.href}
                  iconName={navLink.navLinkIcon}
                  label={navLink.label}
                  onClick={onLinkClick}
                  secondLevelLink
                  iconRight={false}
                  data-testid={`${GLOBAL_HEADER_TEST_ID.OFFCANVASLINK_PREFIX}${kebabCase(navLink.label).toLowerCase()}`}
                />
              </li>
            );
          })}
        </styles.LinkGroup>
      </Stack>
    </nav>
  );
};
MobileLinkGroup.displayName = 'MobileLinkGroup';
