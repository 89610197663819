import styled, { css } from 'styled-components';
import { COLOURS, media, hasAlignment, spacingMixin, mediaMap } from '@belong/themes';
import { StyledCSS } from '@belong/types';
import { IContentFormatter } from './ContentFormatter.types';
import { SHORT_SPACING, LONG_SPACING } from './contentFormatter.config';

const shortSpacing = (spacing): StyledCSS<any> => css`
  ${hasAlignment};

  & > * + * {
    ${mediaMap(
      SHORT_SPACING[spacing],
      (margin: string) => css`
        margin-top: ${margin};
      `
    )}
  }
`;

const longSpacing = (): StyledCSS<any> => css`
  hr {
    margin: 4rem 0;

    ${media('md')`
      margin: 4.8rem 0;
    `}

    /**
     * It appears that a <hr> as the last item in a Rich Text
     * block doesn't get its margins collapsed into the subsequent <section>'s
     * margin-top. So let's make sure this hr doesn't push down then.
     */
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${Object.keys(LONG_SPACING).map(target =>
    Object.keys(LONG_SPACING[target]).map(sibling => {
      return css`
        ${target} + ${sibling} {
          ${mediaMap(
            LONG_SPACING[target][sibling],
            (margin: string) => css`
              margin-top: ${margin};
            `
          )}
        }
      `;
    })
  )}
`;

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['spacing'].includes(prop) && defaultValidatorFn(prop)
})<IContentFormatter>`
  hr {
    border-top: 1px solid ${COLOURS.GREY_300};
    border-bottom: none;
  }
  ${spacingMixin}

  ${({ contentLength, spacing }): StyledCSS<IContentFormatter> =>
    contentLength === 'short' ? shortSpacing(spacing) : longSpacing()};
`;
