import { FC, PropsWithChildren, useContext } from 'react';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';

import { IButton } from '@belong/types';
import {
  Dictionary,
  FElementEmbeddedInformationModal,
  useApplyDictionaryToDocument,
  DictionaryContext
} from '@belong/contentful';

import { Display } from '../Display';
import { Heading } from '../Heading';
import { Copy } from '../Copy';
import EmbeddedInformationModal from '../../../feedback/EmbeddedInformationModal';
import { MultiColumnList, FMultiColumnList } from '../../MultiColumnList';
import ContentFormatter from '../../ContentFormatter';
import BaseRichText from '../BaseRichText/BaseRichText';
import { RENDER_TEXT, warn } from './NodeRenderers/utils';
import listRenderers from './NodeRenderers/Lists';
import tableRenderers from './NodeRenderers/Tables';
import { IRichTextProps, TNODES, TNodeKeys, TEXT_NODES } from './RichText.types';
import {
  countryList,
  elementLink,
  elementSystemIcon,
  moleculeAlert,
  moleculeCountdownTimer,
  moleculeInformationCalloutRow,
  moleculeLinkList,
  moleculeMediaCaptionBlock,
  sectionAccordionList
} from './NodeRenderers/Entries';
import FeedbackCapture, { FFeedbackCapture } from '../../../feedback/FeedbackCapture';

type TNodeRendererOutput = boolean | JSX.Element;

export const NODES: TNODES = {
  display(props): JSX.Element {
    return <Display {...props} />;
  },
  displaySmall(props): JSX.Element {
    return <Display isSmall {...props} />;
  },
  headingLarge(props): JSX.Element {
    return <Heading variant="large" {...props} tabIndex={-1} />;
  },
  headingMedium(props): JSX.Element {
    return <Heading variant="medium" {...props} tabIndex={-1} />;
  },
  headingSmall(props): JSX.Element {
    return <Heading variant="small" {...props} tabIndex={-1} />;
  },
  headingXSmall(props): JSX.Element {
    return <Heading variant="xSmall" {...props} />;
  },
  copyLarge(props): JSX.Element {
    return <Copy variant="large" {...props} />;
  },
  copyMedium(props): JSX.Element {
    return <Copy variant="medium" {...props} />;
  },
  copySmall(props): JSX.Element {
    return <Copy variant="small" {...props} />;
  },
  elementColumns(props: { data }): JSX.Element {
    const { data } = props;
    const { listItems } = FMultiColumnList(data.target.fields);

    return <MultiColumnList listItems={listItems} />;
  },
  countryList: ({ data }): JSX.Element => countryList(data.target),
  elementEmbeddedInformationModal(props: any, buttonProps: Partial<IButton> = { isLightColor: false }): JSX.Element {
    const { fields } = props.data.target;
    const elementProps = FElementEmbeddedInformationModal({ fields, buttonProps });
    return <EmbeddedInformationModal {...elementProps} />;
  },
  elementLink: ({ data }): JSX.Element => elementLink(data.target),
  elementSystemIcon: ({ data }): JSX.Element => elementSystemIcon(data.target),
  moleculeAlert: ({ data }): JSX.Element => moleculeAlert(data.target),
  moleculeCountdownTimer: ({ data }): JSX.Element => moleculeCountdownTimer(data.target),
  moleculeInformationCalloutRow: ({ data }): JSX.Element => moleculeInformationCalloutRow(data.target),
  moleculeLinkList: ({ data }): JSX.Element => moleculeLinkList(data.target),
  moleculeMediaCaptionBlock: ({ data }): JSX.Element => moleculeMediaCaptionBlock(data.target),
  organismFeedbackCapture: ({ data }): JSX.Element => <FeedbackCapture {...FFeedbackCapture(data.target)} />,
  sectionAccordionList: ({ data }): JSX.Element => sectionAccordionList(data.target)
};

export const registerRichTextElements = (newElements: Partial<TNODES>): void => {
  Object.assign(NODES, newElements);
};

export const RENDER_NODE = (key: TNodeKeys, props: PropsWithChildren<any>): TNodeRendererOutput => {
  const nonEmptyChildren = props.children?.filter?.(child => !!child?.[0] || child?.[0] !== '') || [];
  const hasNonEmptyChildren = nonEmptyChildren.length > 0;
  return hasNonEmptyChildren && NODES[key](props);
};

/**
 * Renders the rich text document with the given colors and styles applied.
 * Looks up dictionary values in context to apply to string interpolations.
 *
 * @see libs/providers/dictionary/provider.tsx
 * @see libs/providers/dictionary/hooks.tsx
 *
 * If you'd like to render a specific React component in place of the standard
 * ones supplied here, you can do like so:
 *
 * const customParagraphRenderer = (_, children) => {
 *   return (
 *     <MyCustomComponentInsteadOfNative_P_Element className={'gday-cobba'}>
 *       {children}
 *     </MyCustomComponentInsteadOfNative_P_Element>
 *   )
 * }
 *
 * <RichTextBase
 *    renderNode={{ [BLOCKS.PARAGRAPH]: customParagraphRenderer }}
 *    {...otherProps}
 * />
 */
export const RichTextBase: FC<Omit<IRichTextProps, 'dictionary'>> = ({
  html,
  hasColor,
  h1 = TEXT_NODES.display,
  h2 = TEXT_NODES.displaySmall,
  h3 = TEXT_NODES.headingLarge,
  h4 = TEXT_NODES.headingMedium,
  h5 = TEXT_NODES.headingSmall,
  h6 = TEXT_NODES.headingXSmall,
  p = TEXT_NODES.copyMedium,
  renderNode,
  renderMark,
  renderText = RENDER_TEXT,
  renderBlockEntries = {},
  renderEmbeddedInlines = {},
  headingId
}: IRichTextProps) => {
  const applyDictionaryToDocument = useApplyDictionaryToDocument();
  const dictionary = useContext(DictionaryContext);

  if (!html) {
    return null;
  }
  const document = applyDictionaryToDocument(html);

  // Define available renderers for each document block type.
  // Headings 1-6 and Paragraph elements can have their renderer overridden via props
  const options: Options = {
    renderNode: {
      /*  must have a children */
      [BLOCKS.HEADING_1]: (_, children): TNodeRendererOutput =>
        RENDER_NODE(h1, { children, hasColor, as: 'h1', id: headingId }),
      [BLOCKS.HEADING_2]: (_, children): TNodeRendererOutput =>
        RENDER_NODE(h2, { children, hasColor, as: 'h2', id: headingId }),
      [BLOCKS.HEADING_3]: (_, children): TNodeRendererOutput =>
        RENDER_NODE(h3, { children, hasColor, as: 'h3', id: headingId }),
      [BLOCKS.HEADING_4]: (_, children): TNodeRendererOutput =>
        RENDER_NODE(h4, { children, hasColor, as: 'h4', id: headingId }),
      [BLOCKS.HEADING_5]: (_, children): TNodeRendererOutput =>
        RENDER_NODE(h5, { children, hasColor, as: 'h5', id: headingId }),
      [BLOCKS.HEADING_6]: (_, children): TNodeRendererOutput =>
        RENDER_NODE(h6, { children, hasColor, as: 'h6', id: headingId }),
      [BLOCKS.PARAGRAPH]: (_, children): TNodeRendererOutput => RENDER_NODE(p, { children, hasColor, as: 'p' }),

      ...listRenderers,
      ...tableRenderers,

      /* must be an known contentType */
      [INLINES.EMBEDDED_ENTRY]: ({ data }) => {
        const { id } = data.target.sys.contentType.sys;

        const RENDERABLE_NODES = {
          ...NODES,
          ...renderEmbeddedInlines
        };

        return RENDERABLE_NODES[id]
          ? RENDERABLE_NODES[id]({
              data,
              hasColor,
              serviceId: dictionary?.serviceId,
              orderId: dictionary?.orderId,
              outageId: dictionary?.outageId,
              redirect: dictionary?.redirect,
              testId: dictionary?.testId
            })
          : warn('INLINES.EMBEDDED_ENTRY', data);
      },
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) => {
        const { id } = data.target.sys.contentType.sys;

        const RENDERABLE_NODES = {
          ...NODES,
          ...renderBlockEntries
        };
        return RENDERABLE_NODES[id] ? RENDERABLE_NODES[id]({ data }) : warn('BLOCKS.EMBEDDED_ENTRY', data);
      },

      // Hyperlinks and Assets are NOT rendered by default
      // use `Element > Link` for hyperlinks
      // use `Molecule > Media Caption Block` for images/videos
      [INLINES.HYPERLINK]: ({ data }) => warn('INLINES.HYPERLINK', data),
      [INLINES.ENTRY_HYPERLINK]: ({ data }) => warn('INLINES.ENTRY_HYPERLINK', data),
      [INLINES.ASSET_HYPERLINK]: ({ data }) => warn('INLINES.ASSET_HYPERLINK', data),
      [BLOCKS.EMBEDDED_ASSET]: ({ data }) => warn('BLOCKS.EMBEDDED_ASSET', data),

      // optionally override renderers via props
      ...renderNode
    },
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      ...renderMark
    },
    renderText
  };

  return <BaseRichText options={options}>{document}</BaseRichText>;
};

RichTextBase.displayName = 'RichTextBase';

// Renders the common rich text component but applies alignment and spacing.
export const RichText: FC<IRichTextProps> = ({
  contentLength = 'short',
  'data-testid': testId,
  alignment,
  spacing = 'medium',
  hasMargin,
  hasPadding,
  dictionary = {},
  id,
  contentId,
  ...otherProps
}: IRichTextProps) => (
  <ContentFormatter
    id={id}
    data-testid={testId}
    data-contentid={contentId}
    alignment={alignment}
    spacing={spacing}
    hasMargin={hasMargin}
    hasPadding={hasPadding}
    contentLength={contentLength}
  >
    <Dictionary value={dictionary}>
      <RichTextBase {...otherProps} />
    </Dictionary>
  </ContentFormatter>
);

RichText.displayName = 'RichText';
