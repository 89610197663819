import { useEffect, useMemo, useRef, useState } from 'react';
import { dayjs } from '@belong/date-utils';
import { logger } from '@belong/logging/logger';
import { IUseCountdownTimer, ITimeState } from './types';

export const getOffset = (timeZone = 'UTC', date = new Date()): number => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

const TIMEZONE = 'Australia/Melbourne';
const TIMEZONE_OFFSET = getOffset(TIMEZONE);
export const ERROR_MESSAGE = 'Invalid date provided to CountdownTimer';

const MS_PER_DAY = 86400000;
const MS_PER_HOUR = 3600000;
const MS_PER_MINUTE = 60000;

const calculateRemainingTimeUnits = (remainingMs: number): ITimeState => {
  const days = Math.floor(remainingMs / MS_PER_DAY);
  const hours = Math.floor((remainingMs % MS_PER_DAY) / MS_PER_HOUR);
  const minutes = Math.floor((remainingMs % MS_PER_HOUR) / MS_PER_MINUTE);

  return { remainingDays: days, remainingHours: hours, remainingMinutes: minutes };
};

export const useCountdownTimer = (countdownEnd: string): IUseCountdownTimer => {
  const [isError, setIsError] = useState(false);
  const countdownEndDate = useMemo(() => new Date(countdownEnd), [countdownEnd]);
  const initialState = useMemo(() => {
    const endTime = dayjs(countdownEndDate).utcOffset(TIMEZONE_OFFSET).valueOf();
    const currentTime = dayjs().utcOffset(TIMEZONE_OFFSET).valueOf();
    const remainingMs = endTime - currentTime;
    return {
      isFinished: remainingMs <= 0,
      timeState:
        remainingMs <= 0
          ? { remainingDays: 0, remainingHours: 0, remainingMinutes: 0 }
          : calculateRemainingTimeUnits(remainingMs)
    };
  }, [countdownEndDate]);

  const [isCountdownFinished, setIsCountdownFinished] = useState(initialState.isFinished);
  const [timeState, setTimeState] = useState<ITimeState>(initialState.timeState);

  useEffect(() => {
    if (!(countdownEndDate instanceof Date) || isNaN(countdownEndDate.getTime())) {
      logger.error(ERROR_MESSAGE);
      setIsError(true);
    }
  }, [countdownEndDate]);

  const countdownInterval = useRef<NodeJS.Timeout | null>(null);

  const formattedValues = useMemo(
    () => ({
      days: timeState.remainingDays.toString().padStart(2, '0'),
      hours: timeState.remainingHours.toString().padStart(2, '0'),
      minutes: timeState.remainingMinutes.toString().padStart(2, '0')
    }),
    [timeState]
  );

  const endTime = useMemo(() => {
    return dayjs(countdownEndDate).utcOffset(TIMEZONE_OFFSET).valueOf();
  }, [countdownEndDate]);

  useEffect(() => {
    const calculateRemainingTime = (): void => {
      const currentTime = dayjs().utcOffset(TIMEZONE_OFFSET).valueOf();
      const remainingMs = endTime - currentTime;

      if (remainingMs <= 0) {
        setTimeState({ remainingDays: 0, remainingHours: 0, remainingMinutes: 0 });
        setIsCountdownFinished(true);
        if (countdownInterval.current) {
          clearInterval(countdownInterval.current);
        }
        return;
      }

      setIsCountdownFinished(false);
      setTimeState(calculateRemainingTimeUnits(remainingMs));
    };

    calculateRemainingTime();

    countdownInterval.current = setInterval(calculateRemainingTime, 1000);

    return () => {
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }
    };
  }, [endTime]);

  return {
    isError,
    isCountdownFinished,
    formattedValues
  };
};
