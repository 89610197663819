import React from 'react';
import { IElementSystemIcon, IGlobalHeaderUrls } from '@belong/types';
import * as styles from './styles';
import { GHMobileLink } from '../GHMobileLink';
import { GLOBAL_HEADER_TEST_ID as TEST_ID } from '../../testids';
import { ARIA_CURRENT } from '../../variables';

interface IGHMobileAccountButton {
  handleLogoutClick: () => void;
  handleClose: () => void;
  urls: IGlobalHeaderUrls;
  currentUrl: string | undefined;
  isLoggedIn: boolean;
  loginLabel: string;
  loginIcon: IElementSystemIcon;
  logoutLabel: string;
  logoutIcon: IElementSystemIcon;
}

export const GHMobileAccountButton: React.FC<IGHMobileAccountButton> = ({
  isLoggedIn,
  loginLabel,
  loginIcon,
  logoutLabel,
  logoutIcon,
  urls,
  currentUrl,
  handleClose,
  handleLogoutClick
}) => {
  return (
    <>
      {isLoggedIn ? (
        <styles.StickyAccountButton>
          <GHMobileLink
            data-testid={TEST_ID.MOBILE_MENU_BUTTON_LOGOUT}
            iconName={logoutIcon.icon}
            label={logoutLabel}
            onClick={handleLogoutClick}
          />
        </styles.StickyAccountButton>
      ) : (
        <styles.StickyAccountButton>
          <GHMobileLink
            aria-current={currentUrl === urls.login ? ARIA_CURRENT : undefined}
            data-testid={TEST_ID.MOBILE_MENU_BUTTON_LOGIN}
            href={urls.login}
            iconName={loginIcon.icon}
            label={loginLabel}
            onClick={handleClose}
          />
        </styles.StickyAccountButton>
      )}
    </>
  );
};

GHMobileAccountButton.displayName = 'GHMobileAccountButton';
