import { Fragment } from 'react';
import { logout } from '@belong/user';
import { IGlobalHeaderUrls, IOrganismMenuItem, IHeaderMenu, ColoursValue } from '@belong/types';
import { BLOCKS } from '@contentful/rich-text-types';
import { COLOURS } from '@belong/themes';
import {
  Stack,
  RichText,
  Columns,
  Column,
  StyledContainer,
  ContentWrapper,
  IRichTextProps
} from '@belong/ui-components';
import DropdownLinkGroup from './DropdownLinkGroup';

import { THeaderDropdownWrapper } from '../types';
import { DropdownHeading } from './DropdownHeading';
import * as styles from './styles';
import { DropdownLink } from './DropdownLink';
import { GLOBAL_HEADER_TEST_ID as TEST_ID } from '../../testids';
import { ARIA_CURRENT } from '../../variables';

interface IHeaderDropdown {
  headerMenuContent: IHeaderMenu;
  dropdownMenuContent?: IOrganismMenuItem;
  currentUrl?: string;
  dropdownWrapper: THeaderDropdownWrapper;
  hideMyAccountLinks?: boolean;
  urls: IGlobalHeaderUrls;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onLinkClick?: () => void;
}

const renderSummaryParagraphNode = (color: ColoursValue): IRichTextProps['renderNode'] => ({
  [BLOCKS.PARAGRAPH]: (_, children) => <styles.SummaryCopy hasColor={color}>{children}</styles.SummaryCopy>
});

export const HeaderDropdown = ({
  headerMenuContent,
  dropdownMenuContent,
  currentUrl,
  dropdownWrapper,
  hideMyAccountLinks,
  urls,
  onBlur,
  onKeyDown,
  onLinkClick
}: IHeaderDropdown) => {
  return (
    <>
      <StyledContainer isPadded={false}>
        {dropdownMenuContent && (
          <ContentWrapper showContent={dropdownWrapper === 'content-main'} data-testid={TEST_ID.DROPDOWN_CONTENT}>
            <Columns space={{ xs: 'large', md: 'xlarge' }} alignX="center">
              <Column width="1/4">
                <styles.SummaryContainer data-testid={TEST_ID.DROPDOWN_SUMMARY}>
                  <Stack space="small">
                    <RichText
                      {...dropdownMenuContent.summary}
                      hasColor={COLOURS.BELONG_BLUE}
                      h2="headingMedium"
                      renderNode={renderSummaryParagraphNode(COLOURS.GREY_100)}
                    />
                    {dropdownMenuContent.summaryLink && (
                      <styles.SummaryButton {...dropdownMenuContent.summaryLink} onClick={onLinkClick} />
                    )}
                  </Stack>
                </styles.SummaryContainer>
              </Column>
              {/* Column 1 of the navLinkGroups */}
              <Column width="1/2">
                <styles.MiddleColumnWrapper>
                  {dropdownMenuContent.navGroup1 &&
                    dropdownMenuContent.navGroup1.map((navLinkGroup, idx: number) => (
                      <Column width="1/2" key={navLinkGroup.id}>
                        <DropdownHeading headingColor={navLinkGroup.labelColour}>{navLinkGroup.label}</DropdownHeading>
                        {navLinkGroup.additionalHelpText && <styles.HelpText {...navLinkGroup.additionalHelpText} />}
                        <DropdownLinkGroup
                          navLinkGroup={navLinkGroup}
                          currentUrl={currentUrl}
                          onLinkClick={onLinkClick}
                          onKeyDown={onKeyDown}
                          onBlur={
                            !dropdownMenuContent.navGroup2 &&
                            dropdownMenuContent.navGroup1 &&
                            dropdownMenuContent.navGroup1.length - 1 === idx
                              ? onBlur
                              : undefined
                          }
                        />
                      </Column>
                    ))}
                </styles.MiddleColumnWrapper>
              </Column>
              {/* Column 2 of the navLinkGroups */}
              <Column width="1/4">
                {dropdownMenuContent.navGroup2 && (
                  <Stack>
                    {/* the Stack is cover in case someone decides they wanna add more to this column */}
                    {dropdownMenuContent.navGroup2.map((navLinkGroup, idx: number) => (
                      <Fragment key={navLinkGroup.id}>
                        <DropdownHeading headingColor={navLinkGroup.labelColour}>{navLinkGroup.label}</DropdownHeading>
                        {navLinkGroup.additionalHelpText && <styles.HelpText {...navLinkGroup.additionalHelpText} />}
                        <DropdownLinkGroup
                          navLinkGroup={navLinkGroup}
                          currentUrl={currentUrl}
                          onLinkClick={onLinkClick}
                          onKeyDown={onKeyDown}
                          onBlur={
                            dropdownMenuContent.navGroup2 && dropdownMenuContent.navGroup2.length - 1 === idx
                              ? onBlur
                              : undefined
                          }
                        />
                      </Fragment>
                    ))}
                  </Stack>
                )}
              </Column>
            </Columns>
          </ContentWrapper>
        )}
        {headerMenuContent && (
          <ContentWrapper showContent={dropdownWrapper === 'content-account'}>
            <styles.ProfileMenuFlex>
              <styles.ProfileMenuColumn>
                <nav aria-label="Other mobile links">
                  <styles.MenuList>
                    {headerMenuContent.myServicesLabel && !hideMyAccountLinks && (
                      <li>
                        <DropdownLink
                          align="right"
                          aria-current={currentUrl === urls.myServices ? ARIA_CURRENT : undefined}
                          data-testid={TEST_ID.BUTTON_MY_SERVICES}
                          href={urls.myServices}
                          label={headerMenuContent.myServicesLabel}
                          onClick={onLinkClick}
                        />
                      </li>
                    )}
                    {headerMenuContent.myProfileLabel && !hideMyAccountLinks && (
                      <li>
                        <DropdownLink
                          align="right"
                          aria-current={currentUrl === urls.myProfile ? ARIA_CURRENT : undefined}
                          data-testid={TEST_ID.BUTTON_MY_PROFILE}
                          href={urls.myProfile}
                          label={headerMenuContent.myProfileLabel}
                          onClick={onLinkClick}
                        />
                      </li>
                    )}
                    {headerMenuContent.logoutLabel && (
                      <li>
                        <DropdownLink
                          align="right"
                          data-testid={TEST_ID.BUTTON_LOGOUT}
                          iconName={headerMenuContent.logoutIcon?.icon}
                          label={headerMenuContent.logoutLabel}
                          onClick={() => logout('/')}
                          onKeyDown={onKeyDown}
                          onBlur={onBlur}
                        />
                      </li>
                    )}
                  </styles.MenuList>
                </nav>
              </styles.ProfileMenuColumn>
            </styles.ProfileMenuFlex>
          </ContentWrapper>
        )}
      </StyledContainer>
      <styles.AccentStrip />
    </>
  );
};

HeaderDropdown.displayName = 'HeaderDropdown';
