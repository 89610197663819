// strip undefined values from an object
import { isNull, isUndefined } from './value';

/**
 *
 * @param obj Object
 * @param stripEmptyStrings if it should remove empty strings from object, including nested objects and arrays
 * @returns serializable cleanObject
 */
export const removeObjectEmptyProps = <T = Record<string, any>>(
  obj: Record<string, any> = {},
  stripEmptyStrings = false
): T => {
  return Object.entries(obj).reduce((acc: Record<string, any>, [key, originalVal]) => {
    let val = originalVal; // We use a new variable here to avoid modifying the function parameter
    if (isNull(val) || isUndefined(val)) {
      return acc;
    }
    if (stripEmptyStrings && val === '') {
      return acc;
    }
    if (Array.isArray(val)) {
      val = val
        .filter(item => !(isNull(item) || isUndefined(item) || (stripEmptyStrings && item === '')))
        .map(item => (typeof item === 'object' ? removeObjectEmptyProps(item, stripEmptyStrings) : item));
    } else if (typeof val === 'object' && val !== null) {
      // if it's an object object, recursively remove empty props
      val = removeObjectEmptyProps(val, stripEmptyStrings);
    }
    return { ...acc, [key]: val };
  }, {}) as T;
};

/**
 * Recursively extracts keys from nested objects, optionally prepending each key with a parent key.
 * @param obj Object from which keys are extracted.
 * @param parentKey (Optional) A string key to be used as a prefix for nested keys.
 * @returns string[] array of keys
 *  @example
 * const obj = { a: 1, b: { c: 2, d: { e: 3 } } };
 * const keys = getObjectKeys(obj);
 * // keys will be ['a', 'b.c', 'b.d.e']
 */

export const getObjectKeys = (obj: Record<string, any> = {}, parentKey = ''): string[] => {
  return Object.entries(obj).flatMap(([key, value]) => {
    const currentKey = `${parentKey}${key}`;
    if (typeof value === 'object') {
      return getObjectKeys(value, `${currentKey}.`);
    }
    return currentKey;
  });
};
