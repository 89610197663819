import { GutterBreakpointKey, ITheme, SpacesKeys, TBreakpointKeys } from '@belong/types';

export const BREAKPOINTS: Record<TBreakpointKeys, number> = {
  xs: 0,
  sm: 416, // 25.93em
  md: 792, // 49.5em
  lg: 1024, // 64em
  xl: 1440 // 90em
};

// TBC: see if this can be combined with BREAKPOINTS_MAX_WIDTH from app
export const BREAKPOINTS_MAX_WIDTH: Record<Exclude<TBreakpointKeys, 'xl'>, number> = {
  xs: BREAKPOINTS.sm - 1, // 25.87em
  sm: BREAKPOINTS.md - 1, // 49.4em
  md: BREAKPOINTS.lg - 1, // 63.9em
  lg: BREAKPOINTS.xl - 1 // 89.9em
};

const STANDARD_SPACING: Record<Extract<TBreakpointKeys, 'xs' | 'md'>, string> = {
  xs: '1.6rem',
  md: '2.4rem'
};

export const CONTAINER_MAX_WIDTHS: Record<Exclude<TBreakpointKeys, 'xs' | 'sm'>, string> = {
  md: '80.4rem',
  lg: '93.6rem',
  xl: '131.2rem'
};

export const GRID_GUTTERS: Record<GutterBreakpointKey, string> = {
  xs: '0.8rem', // visible gutter is (2 * 0.8rem)
  md: '1.2rem',
  lg: '1.6rem'
};

export const GRID_VERTICAL_GUTTERS: Record<GutterBreakpointKey, string> = {
  ...STANDARD_SPACING,
  lg: '3.2rem'
};

export const SPACES: Record<SpacesKeys, number> = {
  none: 0,
  xxsmall: 0.4,
  xsmall: 0.8,
  small: 1.2,
  medium: 1.6,
  large: 2.4,
  xlarge: 3.2,
  xxlarge: 4.0,
  xxxlarge: 4.8
};
export const cssUnit: ITheme['cssUnit'] = 'rem';

export const SECTION_VERTICAL_SPACINGS: Record<GutterBreakpointKey, string> = {
  xs: '3.2rem',
  md: '4.8rem',
  lg: '7.2rem'
};

export const SECTION_VERTICAL_INNER_SPACINGS: Record<GutterBreakpointKey, string> = {
  xs: '2.4rem',
  md: '3.2rem',
  lg: '4rem'
};

export const TILE_INNER_PADDING: Record<Extract<TBreakpointKeys, 'xs' | 'md'>, string> = {
  ...STANDARD_SPACING
};

export const TILE_VERTICAL_INNER_SPACINGS: Record<Extract<TBreakpointKeys, 'xs' | 'md'>, string> = {
  ...STANDARD_SPACING
};

export const INPUTS_WRAPPER_VERTICAL_SPACING: Record<Extract<TBreakpointKeys, 'xs' | 'md'>, string> = {
  ...STANDARD_SPACING
};

export const FIELDSET_VERTICAL_SPACING = '0.8rem';
