import {
  CalloutFlairsKey,
  ColoursKey,
  ColoursValue,
  FlairsKey,
  FlairsValue,
  GradientsKey,
  ShadowsKey
} from '@belong/types';

export const COLOURS: Record<ColoursKey, ColoursValue> = {
  WHITE_OVERLAY: 'rgba(255, 255, 255, 0.66)',
  BLACK_OVERLAY: 'rgba(0, 0, 0, 0.75)',

  WHITE: '#FFF',
  GREY_100: '#F8F8F8',
  GREY_200: '#EBECED',
  GREY_300: '#D2D4D6',
  GREY_400: '#B3B3B3',
  GREY_500: '#6B6B6B',
  GREY_600: '#404040',
  GREY_700: '#1B1B1B',
  BLACK: '#000000',

  LIGHT_BLUE: '#DFF3FB',
  MID_BLUE: '#B2D6EB',
  DARK_BLUE: '#0061AB',

  LIGHT_GREEN: '#E0F2F1',
  MID_GREEN: '#C2E1D4',
  BRIGHT_GREEN: '#0acf83',
  DARK_GREEN: '#00794D',

  YELLOW: '#FFF200',

  LIGHT_ORANGE: '#FFF4E3',
  MID_ORANGE: '#FFB38A',
  ALT_ORANGE: '#E8834D',
  DARK_ORANGE: '#C74500',

  LIGHT_RED: '#FFECF1',
  MID_RED: '#EFA5AD',
  ALT_RED: '#DE4B5A',
  RED: '#DC3545',
  DARK_RED: '#C02333',

  PURPLE: '#CC00CC',
  DARK_PURPLE: '#410075',

  // Named/brand colours

  BELONG_PRIMARY: '#000000',

  BELONG_BLUE_LIGHT: '#58CCFB',
  BELONG_BLUE: '#41B6E6',
  BELONG_BLUE_DARK: '#1F9DD2',

  DISCO_PINK: '#FFC9FF',
  DISCO_PINK_DARK: '#FF4BFF',

  MOSAIC_LIGHT: '#F8EFFF',
  PASSION_POP: '#ECD6FF',
  PASSION_POP_DARK: '#C175FF',
  MOSAIC: '#A538FF',

  PEPPERMINT_CRISP: '#C3FBE2',
  PEPPERMINT_CRISP_DARK: '#38F29E'
};

export const FLAIRS: Record<FlairsKey, FlairsValue> = {
  PASSION_POP: 'PASSION_POP',
  DISCO_PINK: 'DISCO_PINK',
  PEPPERMINT_CRISP: 'PEPPERMINT_CRISP',
  BLACK: 'BLACK',
  FOIL_SLICK: 'FOIL_SLICK',
  ELECTRIC_DREAM: 'ELECTRIC_DREAM',
  SANDY_SALMON: 'SANDY_SALMON',
  TOXIC_SLUDGE: 'TOXIC_SLUDGE',
  BLUE: 'BLUE',
  UNAVAILABLE: 'UNAVAILABLE',
  GREEN: 'GREEN'
};

export const FLAIR_COLOURS: Record<FlairsKey, string> = {
  PASSION_POP: 'linear-gradient(90deg, #41B6E6 0%, #C175FF 100%)',
  DISCO_PINK: COLOURS.DISCO_PINK,
  ELECTRIC_DREAM: 'linear-gradient(90deg, #41B6E6 0%, #3B7DFC 100%)',
  SANDY_SALMON: 'linear-gradient(270deg, #FFB177 0%, #41B6E6 100%)',
  TOXIC_SLUDGE: 'linear-gradient(270deg, #C7F238 0%, #41B6E6 100%)',
  PEPPERMINT_CRISP: 'linear-gradient(270deg, #38F29E 0%, #41B6E6 100%)',
  BLACK: COLOURS.BLACK,
  FOIL_SLICK: 'linear-gradient(90deg, #F4B480 0%, #FF4BFF 20%, #C175FF 40%, #41B6E6 60%, #38F29E 80%, #C7F238 100%)',
  BLUE: COLOURS.BELONG_BLUE,
  UNAVAILABLE: COLOURS.GREY_300,
  GREEN: COLOURS.MID_GREEN
};

export const CALLOUT_FLAIR_COLOURS: Record<
  Exclude<CalloutFlairsKey, 'ELECTRIC_DREAM' | 'SANDY_SALMON' | 'TOXIC_SLUDGE'>,
  string
> = {
  PASSION_POP: COLOURS.PASSION_POP,
  DISCO_PINK: COLOURS.DISCO_PINK,
  PEPPERMINT_CRISP: COLOURS.PEPPERMINT_CRISP,
  BLACK: COLOURS.BLACK,
  FOIL_SLICK:
    'linear-gradient(249.19deg, #D2F560 17.27%, #60F5B1 28.61%, #67C5EB 42.93%, #CD91FF 63.36%, #FF6FFF 76.95%, #FFC192 89.3%);',
  UNAVAILABLE: COLOURS.GREY_300,
  GREEN: COLOURS.MID_GREEN
};

export const GRADIENTS: Record<GradientsKey, string> = {
  ACCENT_STRIP_GLOBAL_HEADER: `linear-gradient(
    269.99deg,
    #d0ef5d 0.66%,
    #78eea5 16.31%,
    #62b4e1 36.66%,
    #b67cf7 66.4%,
    #eb5ef7 83.62%,
    #f4b480 100.83%
  )`
};

// See Belong Design System for explanation of shadow names
// https://www.figma.com/file/jQMc7AYX3M3dUPCqV7k49y/Styles?node-id=839%3A1435
export const SHADOWS: Record<ShadowsKey, string> = {
  KEY_LIGHT: '0px 1px 7px rgba(0, 0, 0, 0.2)',
  AMBIENT_LIGHT: '0px 1px 4px rgba(0, 0, 0, 0.15)',
  HOVER: '0px 5px 13px 1px rgba(0, 0, 0, 0.2)'
};
