import { CookiesProvider, Cookies } from 'react-cookie';

import { PlatformConfigProvider } from '@belong/device-bridge';
import { CorrelationIdContext } from '@belong/user';

import { Provider as Authentication } from './authentication';
import { Provider as DeviceCapabilities } from './deviceCapabilities';
import { Provider as PageMeta } from './pageMeta';
import { Provider as Footer } from './footer';
import { Provider as Header } from './header';
import { Provider as Products } from './products';
import { Provider as Runtime } from './runtime';
import { Provider as User } from './user';
import { Provider as AnalyticsEvents, useAnalyticsEvents } from './analyticsEvents';

interface IProviderProps {
  cookies: Cookies;
  correlationId: string;
  children: React.ReactNode;
  additionalProviders?: React.ComponentType<any>[];
}

const Providers: React.FC<IProviderProps> = ({ children, cookies, correlationId, additionalProviders = [] }) => {
  let innerContent = (
    <Header>
      <Footer>
        <AnalyticsEvents>{children}</AnalyticsEvents>
      </Footer>
    </Header>
  );

  additionalProviders.forEach(Provider => {
    innerContent = <Provider>{innerContent}</Provider>;
  });

  const wrappedContent = (
    <CorrelationIdContext.Provider value={correlationId}>
      <CookiesProvider cookies={cookies}>
        <PlatformConfigProvider cookies={cookies}>
          <Authentication>
            <DeviceCapabilities>
              <User>
                <PageMeta>
                  <Runtime>
                    <Products>{innerContent}</Products>
                  </Runtime>
                </PageMeta>
              </User>
            </DeviceCapabilities>
          </Authentication>
        </PlatformConfigProvider>
      </CookiesProvider>
    </CorrelationIdContext.Provider>
  );

  return wrappedContent;
};

export { Providers as default, useAnalyticsEvents };
