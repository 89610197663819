import { forwardRef } from 'react';

import { IOrganismMenuItem } from '@belong/types';
import { Stack } from '@belong/ui-components';
import { MobileLinkGroup } from './MobileLinkGroup';

import * as styles from './styles';
import { ModalAccessibilityHeader } from '../styles';

interface IMobileLinkPanel {
  linkPanelContent: IOrganismMenuItem | undefined;
  currentUrl: string | undefined;
  onLinkClick?: () => void;
}

export const MobileLinkPanel = forwardRef(
  ({ linkPanelContent, currentUrl, onLinkClick }: IMobileLinkPanel, ref: any): JSX.Element => {
    return (
      <>
        <ModalAccessibilityHeader id="linkPanel" tabIndex={-1}>
          Start of link panel
        </ModalAccessibilityHeader>
        <Stack space="xlarge">
          <Stack space="large">
            <styles.LinkPanelHeading summaryLink={false} ref={ref} tabIndex={-1}>
              {linkPanelContent?.parentLabel}
            </styles.LinkPanelHeading>
            {linkPanelContent?.summaryLink && (
              <styles.SummaryButton {...linkPanelContent.summaryLink} onClick={onLinkClick} />
            )}
          </Stack>
          {linkPanelContent?.navGroup1 &&
            linkPanelContent.navGroup1.map(navLinkGroup => (
              <MobileLinkGroup
                key={navLinkGroup.id}
                navLinkGroup={navLinkGroup}
                currentUrl={currentUrl}
                onLinkClick={onLinkClick}
              />
            ))}
          {linkPanelContent?.navGroup2 &&
            linkPanelContent.navGroup2.map(navLinkGroup => (
              <MobileLinkGroup
                key={navLinkGroup.id}
                navLinkGroup={navLinkGroup}
                currentUrl={currentUrl}
                onLinkClick={onLinkClick}
              />
            ))}
        </Stack>
      </>
    );
  }
);

MobileLinkPanel.displayName = 'MobileLinkPanel';
