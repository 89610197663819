import {
  ColoursValue,
  FontColoursKey,
  FontNameKey,
  FontWeightKey,
  IFontSizeVariants,
  ILetterSpacing
} from '@belong/types';

import { COLOURS } from './colour';

export const FONT_NAME: Record<FontNameKey, string> = {
  PROXIMA_NOVA: 'ProximaNova'
};

export const FONT_WEIGHT: Record<FontWeightKey, number> = {
  BOLD: 700,
  SEMI_BOLD: 600,
  REGULAR: 400
};

export const FONT_SIZE_BODY: IFontSizeVariants['body'] = {
  xs: {
    small: '1.5rem',
    medium: '1.7rem',
    large: '1.7rem'
  },
  md: {
    small: '1.5rem',
    medium: '1.7rem',
    large: '2.0rem'
  }
};

export const FONT_SIZE_HEADING: IFontSizeVariants['heading'] = {
  xs: {
    xsmall: '1.7rem',
    small: '2.0rem',
    medium: '2.4rem',
    large: '2.8rem',
    xlarge: '3.2rem',
    xxlarge: '4.4rem'
  },
  md: {
    xsmall: '2.0rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '6.0rem'
  }
};

export const FONT_SIZE: IFontSizeVariants = {
  body: FONT_SIZE_BODY,
  heading: FONT_SIZE_HEADING
};

export const LINE_HEIGHT_BODY: IFontSizeVariants['body'] = {
  xs: {
    small: '2.0rem',
    medium: '2.4rem',
    large: '2.4rem'
  },
  md: {
    small: '2.0rem',
    medium: '2.4rem',
    large: '2.8rem'
  }
};

export const LINE_HEIGHT_HEADING: IFontSizeVariants['heading'] = {
  xs: {
    xsmall: '2.4rem',
    small: '2.8rem',
    medium: '3.2rem',
    large: '3.2rem',
    xlarge: '3.6rem',
    xxlarge: '4.8rem'
  },
  md: {
    xsmall: '2.8rem',
    small: '3.2rem',
    medium: '3.6rem',
    large: '4.8rem',
    xlarge: '4.8rem',
    xxlarge: '6.0rem'
  }
};

export const LINE_HEIGHT: IFontSizeVariants = {
  body: LINE_HEIGHT_BODY,
  heading: LINE_HEIGHT_HEADING
};

export const LETTER_SPACING_HEADING: ILetterSpacing['heading'] = {
  xs: {
    xsmall: '0',
    small: '0',
    medium: '0.2px',
    large: '0.2px',
    xlarge: '0.2px',
    xxlarge: '0.2px'
  },
  md: {
    xsmall: '0',
    small: '0',
    medium: '0.2px',
    large: '-0.5px',
    xlarge: '-1px',
    xxlarge: '-1px'
  }
};

export const LETTER_SPACING: ILetterSpacing = {
  heading: LETTER_SPACING_HEADING
};

export const FONT_COLOURS: Record<FontColoursKey, ColoursValue> = {
  ACCENT: COLOURS.YELLOW,
  ACTIVE: COLOURS.BELONG_BLUE,
  BASE: COLOURS.GREY_600,
  DISABLED: COLOURS.GREY_400,
  ERROR: COLOURS.DARK_RED,
  ERROR_ON_DARK: COLOURS.ALT_RED,
  FINE_PRINT: COLOURS.GREY_500,
  LIGHT: COLOURS.WHITE,
  MESSAGE: COLOURS.DARK_BLUE,
  STRONG: COLOURS.BLACK,
  SUCCESS: COLOURS.DARK_GREEN,
  WARNING: COLOURS.DARK_ORANGE,
  EMPHASIZE: COLOURS.PURPLE,
  MOSAIC: COLOURS.MOSAIC,
  MOSAIC_DARK: COLOURS.DARK_PURPLE
};
