import React, { createContext } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { getConfig } from '@belong/configs/next/config';
import { useReadOnly } from '@belong/react-hooks';

import { ICMSConfig } from './types';
import { makeLookupKey } from './util';

export const CMSConfig = createContext<ICMSConfig | undefined>(undefined);

const ReactQueryDevtools = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
    default: d.ReactQueryDevtools
  }))
);

export const CMSConfigProvider: React.FC<React.PropsWithChildren<{ value: ICMSConfig }>> = ({ children, value }) => {
  const { env, reactQueryDevTools } = getConfig().publicRuntimeConfig;
  const showDevtools = env.isDevelopment && reactQueryDevTools;

  // Create and persist only one QueryClient instance across all renders
  // should have a same effect as create the instance out side of the App
  // https://tanstack.com/query/latest/docs/react/quick-start
  const queryClient = useReadOnly(() => {
    const client = new QueryClient({
      defaultOptions: {
        queries: {
          /**
           * If true, Query will re-fetch when the user refocuses the tab
           * (or clicks back on the page after having focused on DevTools)
           */
          refetchOnWindowFocus: false,
          /**
           * If true, Query will make multiple calls, which is pointless
           */
          retry: false,
          // add stale time to be 1min to have initialData to be take effects for useCMSPage
          staleTime: 60000
        }
      }
    });

    return client;
  });

  // seed the cache with the initial data if passed in
  if (value.initialData) {
    value.initialData.forEach((page, key) => {
      queryClient.setQueryData(makeLookupKey(key), page);
    });
  }

  return (
    <CMSConfig.Provider value={value}>
      <QueryClientProvider client={queryClient}>
        {children}
        {showDevtools && <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />}
      </QueryClientProvider>
    </CMSConfig.Provider>
  );
};
