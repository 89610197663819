import { BORDER_RADIUS } from '@belong/themes';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: bottom;
  padding-bottom: 1.2rem;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
    top: 1px;
  }

  > span {
    margin-right: 1.2rem;
  }
`;

export const ContentBlock = styled.div`
  background-color: ${({ theme }) => theme.backgroundColorLight};
  border-radius: ${BORDER_RADIUS.CARD};
  padding: 1.6rem 1.6rem 0.4rem 1.6rem;
`;
