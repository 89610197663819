import styled, { css } from 'styled-components';
import { AlignX, alignXToFlex, AlignY, alignYToFlex, ITestable, ResponsiveProp, SpacesKeys } from '@belong/types';
import { mediaMap } from '@belong/themes';
import { responsiveValue } from '@belong/ui-core';

export interface IInlineProps extends ITestable {
  space?: ResponsiveProp<SpacesKeys>;
  alignX?: ResponsiveProp<AlignX>;
  alignY?: ResponsiveProp<AlignY>;
}

const inlineDefaultProps: Partial<IInlineProps> = {
  space: 'none',
  alignX: 'left',
  alignY: 'center'
};

/**
 * `Inline` can be used to provide horizontal spacing between some component. It does not set any width on its children.
 * The children's combined width (in addition to the space provided by `Inline`) could go beyond 100% in which case `Inline`
 * will wrap the children to multiple lines.
 */
export const Inline = styled.div<IInlineProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${p =>
    mediaMap(
      responsiveValue(p.space),
      space => css`
        gap: ${p.theme.spaces[space || inlineDefaultProps.space]}${p.theme.cssUnit};
      `
    )};

  ${p =>
    mediaMap(
      responsiveValue(p.alignX),
      alignX => css`
        justify-content: ${alignXToFlex[alignX || inlineDefaultProps.alignX]};
      `
    )};

  ${p =>
    mediaMap(
      responsiveValue(p.alignY),
      alignY => css`
        align-items: ${alignYToFlex[alignY || inlineDefaultProps.alignY]};
      `
    )};
`;
