import { useRouter } from 'next/compat/router';
import { IPageAlert, IHeaderMenu } from '@belong/types';
import useAuthentication from '@belong/providers/authentication';
import { GlobalHeader } from '@belong/global-header';

import { getUrlPath, withBasePath } from '@belong/url-utils';

export interface IGlobalHeaderContainer {
  headerMenuContent: IHeaderMenu;
  pageAlert?: IPageAlert;
  showBackToTopTrigger: boolean;
}

const GlobalHeaderContainer: React.FC<React.PropsWithChildren<IGlobalHeaderContainer>> = ({
  headerMenuContent,
  pageAlert,
  showBackToTopTrigger,
  children
}) => {
  const { isLoggedIn, userType } = useAuthentication();
  const router = useRouter();
  const asPath = router ? router.asPath : '';

  const currentUrl = withBasePath(getUrlPath(asPath));

  return (
    <GlobalHeader
      headerMenuContent={headerMenuContent}
      currentUrl={currentUrl}
      isLoggedIn={isLoggedIn}
      pageAlert={pageAlert}
      showBackToTopTrigger={showBackToTopTrigger}
      userType={userType}
    >
      {children}
    </GlobalHeader>
  );
};

GlobalHeaderContainer.displayName = 'GlobalHeaderContainer';

export default GlobalHeaderContainer;
