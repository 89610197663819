import { ListItem, UnstyledList } from '../../styles/List';
import { ColumnLayout, IColumnLayout } from './CountryList.styles';
import { RegionalCountriesList } from '../RegionalCountriesList';

export interface ICountryList extends IColumnLayout {
  countries: string[];
}

// Display a list of countries in multi-column layout
// Respects CMS ordering
const CountryList: React.FC<ICountryList> = ({ countries, hasColumns }: ICountryList) => {
  // If the first country is prefixed with a *, it's a regional list
  const isRegionalCountryList = countries && countries.length > 0 && countries[0].startsWith('*');

  if (isRegionalCountryList) {
    return <RegionalCountriesList countries={countries} noCollapse />;
  }

  return (
    <ColumnLayout hasColumns={hasColumns} variant="small">
      <UnstyledList>
        {countries.map(country => (
          <ListItem key={country}>{country}</ListItem>
        ))}
      </UnstyledList>
    </ColumnLayout>
  );
};

CountryList.displayName = 'CountryList';
export default CountryList;
