import React, { useEffect, useRef } from 'react';
import structuredClone from '@ungap/structured-clone';
import { ThemeProvider } from 'styled-components';
import merge from 'lodash/merge';

import { ALERT_LEVELS, ALERT_POSITION, ICON_NAMES } from '@belong/types';
import type { IAlert, ITestable, TAlertLevel, ObjectValues } from '@belong/types';
import { BUTTON_VARIANTS, COLOURS, FONT_COLOURS } from '@belong/themes';

import { ALERT, ALERT_DISMISS_BUTTON, ALERT_LINK } from '../../../helpers/testIds';
import { AlertFocus, CrossIcon, DismissButton, Icon, Link, MaxWidthContainer, Root, TextWrapper } from './Alert.styles';

const iconMapping = new Map<TAlertLevel, ICON_NAMES>([
  [ALERT_LEVELS.ERROR, ICON_NAMES.Danger],
  [ALERT_LEVELS.MESSAGE, ICON_NAMES.Info],
  [ALERT_LEVELS.SUCCESS, ICON_NAMES.TickCircle],
  [ALERT_LEVELS.WARNING, ICON_NAMES.Warning],
  [ALERT_LEVELS.MOSAIC, ICON_NAMES.Info]
]);

interface IAlertTheme {
  foregroundColor: ObjectValues<typeof FONT_COLOURS>;
  backgroundColor: ObjectValues<typeof COLOURS>;
  button: {
    quaternary: {
      color: ObjectValues<typeof COLOURS>;
      focus: {
        color: ObjectValues<typeof COLOURS>;
      };
    };
  };
}

const themeMapping = new Map<TAlertLevel, IAlertTheme>([
  [
    ALERT_LEVELS.ERROR,
    {
      foregroundColor: FONT_COLOURS.ERROR,
      backgroundColor: COLOURS.LIGHT_RED,
      button: {
        quaternary: {
          color: FONT_COLOURS.ERROR,
          focus: {
            color: FONT_COLOURS.ERROR
          }
        }
      }
    }
  ],
  [
    ALERT_LEVELS.MESSAGE,
    {
      foregroundColor: FONT_COLOURS.MESSAGE,
      backgroundColor: COLOURS.LIGHT_BLUE,
      button: {
        quaternary: {
          color: FONT_COLOURS.MESSAGE,
          focus: {
            color: FONT_COLOURS.MESSAGE
          }
        }
      }
    }
  ],
  [
    ALERT_LEVELS.SUCCESS,
    {
      foregroundColor: FONT_COLOURS.SUCCESS,
      backgroundColor: COLOURS.LIGHT_GREEN,
      button: {
        quaternary: {
          color: FONT_COLOURS.SUCCESS,
          focus: {
            color: FONT_COLOURS.SUCCESS
          }
        }
      }
    }
  ],
  [
    ALERT_LEVELS.WARNING,
    {
      foregroundColor: FONT_COLOURS.WARNING,
      backgroundColor: COLOURS.LIGHT_ORANGE,
      button: {
        quaternary: {
          color: FONT_COLOURS.WARNING,
          focus: {
            color: FONT_COLOURS.WARNING
          }
        }
      }
    }
  ],
  [
    ALERT_LEVELS.MOSAIC,
    {
      foregroundColor: FONT_COLOURS.MOSAIC_DARK,
      backgroundColor: COLOURS.MOSAIC_LIGHT,
      button: {
        quaternary: {
          color: FONT_COLOURS.MOSAIC_DARK,
          focus: {
            color: FONT_COLOURS.MOSAIC_DARK
          }
        }
      }
    }
  ]
]);

const Alert: React.FC<IAlert & ITestable> = ({
  children,
  className,
  innerRef,
  hasFocus,
  hasPadding,
  link,
  onDismiss,
  position,
  role,
  type,
  code,
  'data-testid': dataTestId = ALERT,
  asNotification,
  ...otherProps
}) => {
  const focusRef = useRef<HTMLDivElement>(null);
  const icon = iconMapping.get(type);
  const themeColors = themeMapping.get(type)!;
  const hasBackground = position !== ALERT_POSITION.INLINE;

  const dismissButton = onDismiss && (
    <DismissButton
      onClick={onDismiss}
      aria-label="Close alert"
      data-testid={ALERT_DISMISS_BUTTON}
      canExpand={position === ALERT_POSITION.GLOBAL}
    >
      <CrossIcon />
    </DismissButton>
  );

  useEffect(() => {
    if (focusRef && focusRef.current !== null && hasFocus) {
      focusRef.current.focus();
    }
  }, []);
  // If `hasFocus` is true we don't want the role to be alert as it may have adverse effects
  const alertRole = role ?? 'alert';
  const roleToApply = (alertRole === 'alert' && hasFocus) || asNotification ? undefined : alertRole;

  return (
    <ThemeProvider theme={theme => merge(structuredClone(theme), themeColors)}>
      <AlertFocus ref={focusRef} tabIndex={-1} data-alert-type={type} data-code={code} data-testid={dataTestId}>
        <Root
          className={`${className} alert`}
          role={roleToApply}
          withBackground={hasBackground}
          withDismissButton={!!onDismiss}
          withColoredGutter={position === ALERT_POSITION.BLOCK}
          isCentered={position === ALERT_POSITION.GLOBAL}
          hasCurvedBorder={position === ALERT_POSITION.BLOCK}
          canExpand={position === ALERT_POSITION.GLOBAL}
          ref={innerRef}
          {...otherProps}
        >
          {icon && <Icon name={icon} hasColor={themeColors.foregroundColor} aria-hidden="true" />}
          <TextWrapper isCentered={position === ALERT_POSITION.GLOBAL}>
            {children}
            {link && <Link data-testid={ALERT_LINK} {...link} variant={BUTTON_VARIANTS.QUATERNARY} />}
          </TextWrapper>
          {dismissButton &&
            position !== ALERT_POSITION.INLINE &&
            (position === ALERT_POSITION.GLOBAL ? (
              <MaxWidthContainer constrainWidth={position === ALERT_POSITION.GLOBAL}>{dismissButton}</MaxWidthContainer>
            ) : (
              dismissButton
            ))}
        </Root>
      </AlertFocus>
    </ThemeProvider>
  );
};

Alert.displayName = 'Alert';
export default Alert;
