import { ISpacingProps } from '@belong/types';
import { COLOURS, media, spacingMixin } from '@belong/themes';
import styled, { css } from 'styled-components';
import { AccordionRowVariant } from './AccordionRow.types';
import ButtonUnstyled from '../ButtonUnstyled';
import Icon from '../../Icon/Icon';

interface IAccordionWrapper extends ISpacingProps {
  variant?: AccordionRowVariant;
  hasBottomBorder?: boolean;
  hasBackground?: string;
  isOpen: boolean;
}

const onHoverOpacity = 'rgba(0, 0, 0, 0.03)';
const borderLeft = ({ isOpen, variant }: IAccordionWrapper) => {
  let style = '0.3rem transparent';
  switch (variant) {
    case 'slim':
      style = '0.1rem transparent';
      if (isOpen) {
        style = `0.1rem ${COLOURS.GREY_500}`;
      }
      return style;
    default:
      if (isOpen) {
        style = `0.3rem ${COLOURS.BELONG_BLUE_DARK}`;
      }
      return style;
  }
};

export const Wrapper = styled.div<IAccordionWrapper>`
  ${spacingMixin}
  background-color: ${({ hasBackground }) => hasBackground === 'grey' && COLOURS.GREY_100};
  border-left: solid ${borderLeft};

  &:hover {
    border-left: solid ${borderLeft};
  }

  ${props =>
    props.hasBottomBorder &&
    css`
      border-bottom: 1px solid ${COLOURS.GREY_300};
    `};
`;

interface IAccordionButton {
  hasVerticalBorder?: boolean;
  variant?: AccordionRowVariant;
  available?: boolean;
}

export const Button = styled(ButtonUnstyled).attrs({ type: 'button' })<IAccordionButton>`
  width: 100%;
  position: relative;
  .accordion-row-inline-icon {
    color: ${({ available }) => !available && COLOURS.GREY_500};
  }

  &:hover {
    cursor: pointer;
    background-color: ${onHoverOpacity};
  }

  &:focus {
    outline: 0.2rem solid ${COLOURS.BELONG_BLUE_DARK};
    outline-offset: -0.8rem;
    background-color: ${onHoverOpacity};
  }

  ${({ variant }) => {
    switch (variant) {
      case 'slim':
        return css`
          padding: 1rem 1.2rem;

          ${media('md')`
            padding: 1.2rem;
          `}
        `;

      default:
        return css`
          padding: 2.4rem 6rem 2.4rem 1.6rem;
          ${media('md')`
            padding-right: 6.8rem;
          `}
        `;
    }
  }};

  ${props =>
    props.hasVerticalBorder &&
    css`
      border-top: 1px solid ${COLOURS.GREY_300};
      border-bottom: 1px solid ${COLOURS.GREY_300};
    `};
`;

export const Chevron = styled(Icon)<{ isOpen: boolean; variant?: AccordionRowVariant }>`
  position: absolute;

  transform: ${({ isOpen }): string => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.5s;

  ${({ variant }) => {
    switch (variant) {
      case 'slim':
        return css`
          right: 1.2rem;
          top: 1rem;
          ${media('md')`
            top: 1.4rem;
        `}
        `;

      default:
        return css`
          right: 2.4rem;
          top: 2.4rem;
        `;
    }
  }};
`;

export const Body = styled.div<{ variant?: AccordionRowVariant } & ISpacingProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'slim':
        return css`
          padding: 1.2rem 1.2rem;

          ul li:first-child {
            padding-top: 0;
          }
          ul li {
            padding: 0.8rem 0 !important;
          }

          ${media('md')`
            ul li {
              padding: 1.6rem 0;
            }
      `}
        `;

      default:
        return css`
          padding: 2.4rem 1.6rem;
        `;
    }
  }};
`;

export const StyledIconContainer = styled.span`
  display: flex;
  align-items: flex-start;

  ${media('md')`
    padding-right: 1.5rem;
  `}
`;

export const StyledIcon = styled(Icon)`
  color: ${COLOURS.GREY_600};
  text-align: center;
  margin-right: 0.8rem;

  ${media('md')`
    margin-right: 1.2rem;
  `}
`;

export const Label = styled.span`
  margin-right: 2rem;

  ${media('md')`
    margin-right: 1.2rem;
  `}
`;
