import React from 'react';

import { ICON_NAMES } from '@belong/types';

import * as styles from './styles';

export interface IDropdownLink {
  'aria-current'?: any;
  'aria-label'?: string;
  align?: 'left' | 'right';
  href?: string;
  iconName?: ICON_NAMES;
  label: string;
  onClick?: any;
  onBlur?: any;
  onKeyDown?: any;
  id?: string;
}

export const DropdownLink: React.FC<IDropdownLink> = ({
  label,
  iconName,
  align = 'left',
  ...wrapperProps
}: IDropdownLink) => (
  <styles.Wrapper align={align} {...wrapperProps}>
    {iconName && <styles.StyledIcon name={iconName} />}
    {label}
  </styles.Wrapper>
);
DropdownLink.displayName = 'DropdownLink';
