import { MaybeStyledCSS } from '@belong/types';
import { BUTTON_VARIANTS, COLOURS, FONT_WEIGHT, getBodyFont, media } from '@belong/themes';
import styled, { css } from 'styled-components';
import IconBase from '../../Icon/Icon';
import { BaseStyles, BaseFontStyles, getVariantStyles } from '../Button';

const PromptMessage = styled.span`
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  ${getBodyFont('xs', 'small')}
  position: relative;
`;

export const Icon = styled(IconBase)`
  ${media('md')`
    margin-right: 3px;
  `}
`;

export const FullPrompt = styled(PromptMessage)`
  display: none;

  ${media('md')`
    display: block;
  `}
`;

export const TruncatedPrompt = styled(PromptMessage)`
  display: block;

  ${media('md')`
    display: none;
  `}
`;

export const Root = styled.button<{ withShadow?: boolean; shouldShow: boolean }>`
  ${BaseStyles};
  ${BaseFontStyles};
  ${getVariantStyles({ variant: BUTTON_VARIANTS.PRIMARY })};

  color: ${COLOURS.BLACK};
  border: 2px solid ${COLOURS.LIGHT_BLUE};
  background: ${COLOURS.LIGHT_BLUE};

  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 48px;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 45px;

  &:hover {
    border: 2px solid ${COLOURS.BELONG_BLUE};
    background: ${COLOURS.BELONG_BLUE};
  }

  &:focus {
    outline-offset: 2px;
    outline: 0.2rem solid ${COLOURS.BELONG_BLUE};
  }

  &:active {
    border: 2px solid ${COLOURS.BELONG_BLUE};
    background: ${COLOURS.BELONG_BLUE};
    text-decoration: underline;
  }

  ${({ withShadow }): MaybeStyledCSS =>
    withShadow &&
    css`
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    `};

  /**
   * background and border tween is from Button PrimaryStyles
   */
  transition:
    opacity 0.5s linear,
    0.2s background-color ease,
    0.2s border-color ease;

  opacity: ${(props): number => (props.shouldShow ? 1 : 0)};
  pointer-events: ${(props): string => (props.shouldShow ? 'auto' : 'none')};

  ${media('md')`
    width: auto;
    flex-direction: row;
    padding: 0 12px 0 8px;
  `};
`;

export const ScreenReaderOnly = styled.span``;
