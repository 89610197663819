import { BORDER_RADIUS, COLOURS, FLAIR_COLOURS, FONT_COLOURS } from '@belong/themes';
import { isFlairColor } from '@belong/themes/helpers/value';
import styled, { css } from 'styled-components';
import { Heading } from '@belong/ui-components';

export const DropdownHeading = styled(Heading).attrs({ variant: 'small', as: 'h3' })`
  ${({ headingColor }) => {
    if (isFlairColor(headingColor)) {
      return css`
        background-image: ${FLAIR_COLOURS[headingColor]};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
      `;
    }
    return css`
      color: ${COLOURS[headingColor]};
    `;
  }};

  border: 2px solid transparent;
  outline: none;
  border-radius: ${BORDER_RADIUS.CARD};

  width: 100%;
  margin: 0 -1rem;
  padding: 0 0.8rem 0.8rem;

  &:focus {
    border: 0.2rem solid ${COLOURS.BELONG_BLUE_DARK};
    color: ${FONT_COLOURS.ACTIVE};
    outline: none;
  }
`;
