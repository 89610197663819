import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { FONT_WEIGHT, getHeadingFont, media, spacingMixin } from '@belong/themes';
import { IHeading, THeadingLevel, VARIANT_TO_HEADING_LEVEL } from '@belong/types';
import { Text } from '../Text';

// TODO: Eliminate the use of heading level to determine font style. This is a semantic error
export const getAsPropFromVariant = ({ as, variant }: IHeading): THeadingLevel => {
  if (as) {
    return as;
  }

  if (!variant || !VARIANT_TO_HEADING_LEVEL[variant]) {
    return VARIANT_TO_HEADING_LEVEL.large; // fallback
  }

  return VARIANT_TO_HEADING_LEVEL[variant];
};

/**
 * The `Heading` component is used to render headings.
 */
export const Heading = styled(Text).attrs((props: IHeading) => ({
  hasColor: props.hasColor,
  variant: props.variant || 'large',
  as: getAsPropFromVariant(props)
}))<IHeading>`
  ${spacingMixin}

  color: ${({ hasColor, theme }) => hasColor ?? theme.foregroundColorDark};
  text-transform: none;

  ${({ variant }): FlattenSimpleInterpolation => {
    switch (variant) {
      case 'xSmall':
        return css`
          ${getHeadingFont('xs', 'xsmall')}
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};

          ${media('md')`
            ${getHeadingFont('md', 'xsmall')}
          `}
        `;

      case 'small':
        return css`
          ${getHeadingFont('xs', 'small')}
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};

          ${media('md')`
            ${getHeadingFont('md', 'small')}
          `}
        `;

      case 'medium':
        return css`
          ${getHeadingFont('xs', 'medium')}
          font-weight: ${FONT_WEIGHT.BOLD};

          ${media('md')`
            ${getHeadingFont('md', 'medium')}
          `}
        `;

      default:
      case 'large':
        return css`
          ${getHeadingFont('xs', 'large')}
          font-weight: ${FONT_WEIGHT.BOLD};

          ${media('md')`
            ${getHeadingFont('md', 'large')}
          `}
        `;
    }
  }};
`;
