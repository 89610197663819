import { ILegacyPublicRuntimeConfig } from './types';

export type { IConfigGetter, ILegacyPublicRuntimeConfig } from './types';

/**
 * Process inlining (replacing process.env with actual values) happens at build
 * time for any properties that are accessed via the exact mechanism `process.env.*`
 *
 * Since we want the values to have their actual values at runtime, we need to
 * ensure we don't get Next.js to inline them. We do that by accessing them via
 * function instead. Webpack will not "optimize" them for us.
 */
export const getProcess = (): { env: Record<string, any> } => {
  return globalThis.process; // points to our own `window.process` shim via <ClientSideEnv />
};

const getRuntimeVars = (): ILegacyPublicRuntimeConfig => {
  const { env } = getProcess();

  return {
    reactQueryDevTools: env.NEXT_PUBLIC_REACT_QUERY_DEV_TOOLS === 'true',
    reduxDevTools: env.NEXT_PUBLIC_REDUX_DEV_TOOLS === 'true',
    env: {
      isProduction: process.env.NODE_ENV === 'production',
      isDevelopment: process.env.NODE_ENV === 'development',
      isTest: process.env.NODE_ENV === 'test',
      isLocal: env.NEXT_PUBLIC_LOCAL === 'true',
      /**
       * Can't use ENVS.PRODUCTION via @belong/lib/types here (it would cause a circular dependency)
       */
      stage: env.NEXT_PUBLIC_STAGE || 'PRODUCTION', // if we don't know which it is, act like it's prod (safe and stable)
      host: env.NEXT_PUBLIC_HOST
    },
    workspace: env.NEXT_PUBLIC_WORKSPACE,
    basePath: env.NEXT_PUBLIC_BASE_PATH,
    auth: {
      domain: env.NEXT_PUBLIC_AUTH0_DOMAIN,
      clientId: env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
      audience: env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      mfaAudience: env.NEXT_PUBLIC_MFA_AUDIENCE
    },
    // contentful sdk requires the value during build time
    // however, with our current pipeline, env variables are
    // injected during runtime only
    contentful: {
      space: env.NEXT_PUBLIC_CONTENTFUL_SPACE || 'mock-space',
      environment: env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT || 'mock-env',
      host: env.NEXT_PUBLIC_CONTENTFUL_HOST || 'mock-host',
      accessToken: env.NEXT_PUBLIC_CONTENTFUL_ACCESS || 'accessToken',
      previewAccessToken: env.NEXT_PUBLIC_CONTENTFUL_ACCESS_PREVIEW || 'previewAccessToken'
    },
    analytic: {
      // new-relic is enabled by default. Only disable if explicitly turned off in props file
      newRelicEnabled: env.NEXT_PUBLIC_NEW_RELIC_ENABLED !== 'false',
      shouldRun: env.NEXT_PUBLIC_ANALYTIC_SHOULD_RUN === 'true',
      atmUri: env.NEXT_PUBLIC_ATM_URI,
      atmUriPayments: env.NEXT_PUBLIC_ATM_URI_PAYMENTS,
      gtmUri: env.NEXT_PUBLIC_GTM_URI,
      gtmId: env.NEXT_PUBLIC_GTM_ID
    },
    constants: {
      simProductId: env.NEXT_PUBLIC_SIM_PRODUCT_ID,
      catalogueMobilePlans: env.NEXT_PUBLIC_CATALOGUE_MOBILE_PLANS,
      braintreeToken: env.NEXT_PUBLIC_BRAINTREE_TOKEN,
      paypalAppClientId: env.NEXT_PUBLIC_PAYPAL_APP_CLIENT_ID
    },
    gateway: {
      host: env.NEXT_PUBLIC_GATEWAY_HOST,
      hostBlue: env.NEXT_PUBLIC_GATEWAY_HOST_BLUE,
      consumerId: env.NEXT_PUBLIC_GATEWAY_CONSUMER_ID
    },
    featureTogglesApiUrl: env.NEXT_PUBLIC_FEATURE_TOGGLES_URL,
    liveagent: {
      deploymentId: env.NEXT_PUBLIC_LIVEAGENT_DEPLOYMENT_ID,
      organizationId: env.NEXT_PUBLIC_LIVEAGENT_ORGANIZATION_ID,
      buttonId: env.NEXT_PUBLIC_LIVEAGENT_BUTTON_ID,
      baseUrl: env.NEXT_PUBLIC_LIVEAGENT_BASE_URL
    },
    greenId: {
      apiKey: env.NEXT_PUBLIC_GREEN_ID_API_KEY,
      /**
       * Valid values are:
       * "test", "au-test", "au-stag", "au-dev1", "au-stag2",
       * "au-dev2", "au-dev3", "au-dev4", "au-test-vv", "prod", "au-prod"
       */
      environmentGroup: env.NEXT_PUBLIC_GREEN_ID_ENVIRONMENT_GROUP,
      accountId: env.NEXT_PUBLIC_GREEN_ID_ACCOUNT_ID,
      stylesheetURI: env.NEXT_PUBLIC_GREEN_ID_STYLESHEET_URI,
      sdkURI: env.NEXT_PUBLIC_GREEN_ID_SDK_URI,
      configURI: env.NEXT_PUBLIC_GREEN_ID_CONFIG_URI
    },
    agent: {
      agentAuthCheck: env.NEXT_PUBLIC_AGENT_AUTH_CHECK,
      agentRedirectHost: env.NEXT_PUBLIC_AGENT_AUTH_CHECK_REDIRECT_HOST,
      agentLogout: env.NEXT_PUBLIC_AGENT_LOGOUT
    },
    aws: {
      region: env.NEXT_PUBLIC_AWS_CONNECT_REGION
    },
    liveChat: {
      scriptLocation: env.NEXT_PUBLIC_LIVE_CHAT_SCRIPT_LOCATION,
      chatPageLocation: env.NEXT_PUBLIC_LIVE_CHAT_PAGE_LOCATION
    },
    adobeTarget: {
      client: env.NEXT_PUBLIC_ADOBE_TARGET_CLIENT,
      organizationId: env.NEXT_PUBLIC_ADOBE_TARGET_ORGANISATION_ID,
      timeout: env.NEXT_PUBLIC_ADOBE_TARGET_TIMEOUT,
      serverDomain: env.NEXT_PUBLIC_ADOBE_TARGET_SERVER_DOMAIN,
      propertyToken: env.NEXT_PUBLIC_ADOBE_TARGET_PROPERTY_TOKEN
    },
    typesense: {
      apiKey: env.NEXT_PUBLIC_TYPESENSE_API_KEY,
      host: env.NEXT_PUBLIC_TYPESENSE_HOST,
      path: env.NEXT_PUBLIC_TYPESENSE_PATH,
      port: env.NEXT_PUBLIC_TYPESENSE_PORT,
      protocol: env.NEXT_PUBLIC_TYPESENSE_PROTOCOL
    },
    partner: {
      clientId: env.NEXT_PUBLIC_SALESFORCE_CLIENT_ID,
      clientSecret: env.NEXT_PUBLIC_SALESFORCE_CLIENT_SECRET,
      redirectHost: env.NEXT_PUBLIC_CALLBACK_URL,
      tokenURL: env.NEXT_PUBLIC_SF_TOKEN_URL,
      oAuthURL: env.NEXT_PUBLIC_BOBO_AUTH_URL
    }
  } as const;
};

export const getConfig = (): {
  publicRuntimeConfig: ILegacyPublicRuntimeConfig;
} => ({ publicRuntimeConfig: getRuntimeVars() });
