import { ICON_NAMES, ISpacingProps, MaybeStyledCSS, ObjectValues, StyledCSS } from '@belong/types';
import { CONTAINER_MAX_WIDTHS, FONT_COLOURS, isFocused, media, spacingMixin } from '@belong/themes';
import styled, { css } from 'styled-components';
import IconBase from '../../Icon/Icon';
import { Button } from '../../actions/Button';

const HEIGHTS = {
  STANDARD: '48px',
  LARGER: '56px'
};

interface IWithThemeColor {
  theme: {
    foregroundColor: ObjectValues<typeof FONT_COLOURS>;
  };
}

const dismissButtonSize = `48px`;

export const AlertFocus = styled.div`
  width: 100%;

  &:focus {
    ${(): ReturnType<typeof isFocused> => isFocused()}
  }
`;

export const Root = styled.div<
  IWithThemeColor &
    ISpacingProps & {
      withBackground?: boolean;
      withDismissButton?: boolean;
      withColoredGutter?: boolean;
      canExpand?: boolean;
      isCentered?: boolean;
      hasCurvedBorder?: boolean;
      alertHeight?: string;
    }
>`
  ${spacingMixin}

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  ${media('lg')`
    justify-content: ${(props): string => (props.isCentered ? 'center' : 'flex-start')};
  `};

  align-items: flex-start;
  position: relative;
  color: ${(props): StyledCSS => props.theme.foregroundColor};
  width: 100%;
  ${({ alertHeight }): StyledCSS =>
    alertHeight
      ? css`
          height: ${alertHeight};
        `
      : ''};

  ${(props): MaybeStyledCSS =>
    props.withColoredGutter &&
    css`
      border-left: 4px solid ${props.theme.foregroundColor};
    `};

  // add a semi-transparent background when required
  // and a cool left-edge border
  ${(props): MaybeStyledCSS =>
    props.withBackground &&
    css`
      padding: 13px 10px;
      min-height: 48px;

      background: ${props.theme.backgroundColor};
    `};

  ${(props): MaybeStyledCSS =>
    props.withDismissButton &&
    css`
      padding-right: ${dismissButtonSize};
    `};

  ${(props): MaybeStyledCSS =>
    props.canExpand &&
    media('lg')`
      padding-top: 16px;
      padding-bottom: 16px;
    `};

  ${(props): MaybeStyledCSS =>
    props.hasCurvedBorder &&
    css`
      border-radius: 4px;
    `}
`;

export const Icon = styled(IconBase).attrs(() => ({ size: 'small' }))<IWithThemeColor>`
  margin-right: 8px;
`;

export const Link = styled(Button)`
  color: ${(props): StyledCSS => props.theme.foregroundColor};
  font-weight: 600;
  margin-left: 0.5ch;
`;

export const CrossIcon = styled(IconBase).attrs(() => ({ name: ICON_NAMES.Close, size: 'small' }))``;

export const DismissButton = styled.button<{ canExpand?: boolean }>`
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  width: ${dismissButtonSize};
  height: ${HEIGHTS.STANDARD};
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props): MaybeStyledCSS =>
    props.canExpand &&
    media('lg')`
    height: ${HEIGHTS.LARGER}
  `};
`;

export const TextWrapper = styled.div<{ isCentered?: boolean }>`
  position: relative;
  top: 0.2ex;
  flex: 1;

  a {
    display: inline;
    span {
      display: inline;
    }
  }

  ${(props): MaybeStyledCSS =>
    props.isCentered &&
    css`
      @media only screen and (min-width: 70ch) {
        max-width: 70ch;
      }

      ${media('lg')`
        text-align: center;
      `};
    `};
`;

export const MaxWidthContainer = styled.div<{ constrainWidth?: boolean }>`
  ${media('xl')`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: ${CONTAINER_MAX_WIDTHS.xl};
  `};
`;
