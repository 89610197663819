import useLocation from 'react-use/lib/useLocation';
import { FEATURES } from '@belong/feature-toggles';
import { useFeatureToggles } from '@belong/feature-toggles/hooks';
import { SUPPORT_PAGE, IDV_PAGE } from './constants';

const pathAllowsNewChat = (path: string = '', allowedPathPrefixes: string[]): boolean => {
  return allowedPathPrefixes.some(prefix => path.startsWith(prefix));
};

export const useCanStartChat = (): boolean => {
  const { pathname } = useLocation();
  const { isFeatureOn } = useFeatureToggles();

  const allowedPathPrefixes = [SUPPORT_PAGE];
  if (isFeatureOn(FEATURES.LIVE_CHAT_SESSIONS_V2)) {
    allowedPathPrefixes.push(IDV_PAGE);
  }

  return pathAllowsNewChat(pathname, allowedPathPrefixes);
};
