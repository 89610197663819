import camelCase from 'lodash/camelCase';

/**
 * MyAccount has Vanity URLs on the following routes
 * which means their Contentful entries are registered
 * on the URLs without basepaths.
 *
 * This compat function exists to keep the peace and some compat until we can
 * migrate them to a nicer way
 */
const applyWeirdExceptions = (path: string): string => {
  if (path === '/new/login') {
    return '/login';
  }

  if (path === '/new/forgot-password') {
    return '/forgot-password';
  }

  if (path === '/new/forgot-password/success') {
    return '/forgot-password/success';
  }

  // otherwise it's not a weird one, we can press on as normal
  return path;
};

/**
 * @example
 *
 * getCMSURLFromPath("/[session_token]/hello/[world]") // => `/${basePath}/:sessionToken/hello/:world`
 */
export const getCMSURLFromPath = (path: string, addBasePathToUrl: (url: string) => string): string => {
  let result = path;

  /**
   * All pages in the CMS are authored with the app's basePath on it, so if the
   * app doesn't give us the basePath in the input, we need to add it.
   */
  result = addBasePathToUrl(result);

  const subpathChunks = result
    .split('/')
    .map(chunk => chunk.trim())
    .map(chunk => {
      if (chunk.startsWith('[') && chunk.endsWith(']')) {
        return `:${camelCase(chunk.slice(1, -1))}`;
      }

      return chunk;
    });

  return applyWeirdExceptions(subpathChunks.join('/'));
};

const takeFirstValue = (value: string | string[] | undefined): string | undefined => {
  if (Array.isArray(value)) {
    return value[0];
  }

  return value;
};

export const flattenValuesInObject = (obj: Record<string, string | string[] | undefined>): Record<string, string> => {
  const result = {};

  Object.keys(obj).forEach(key => {
    result[key] = takeFirstValue(obj[key]);
  });

  return result;
};

export const makeLookupKey = (cmsKey: string): string[] => {
  return ['cms page', cmsKey];
};
