import { ZIndexKey } from '@belong/types';

export * from './variables/alignment';
export * from './variables/button';
export * from './variables/colour';
export * from './variables/font';
export * from './variables/icon';
export * from './variables/spacing';

export const Z_INDEX: Record<ZIndexKey, number> = {
  PAGE_TITLE: -1,
  LIST_BOX: 505,
  SEARCH_BOX: 500,
  SEARCH_BOX_PROGRESS_BAR: 501,
  CARD_BUSY_OVERLAY: 1000,
  CARD_BUSY_PROGRESS_BAR: 1010,
  GLOBAL_HEADER: 1500,
  SKIP_LINK: 1700,
  MODAL: 1900,
  PROGRESS_INDICATOR: 2000,
  NATIVE_NAVIGATION: 3000
};

export const BORDER_RADIUS = {
  BUTTON: '4rem',
  CARD: '0.8rem',
  INPUT: '0.4rem'
} as const;

export const FOCUS_RING_WIDTH = 0.2; // (in rems)
