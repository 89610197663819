import { ICON_NAMES, IOrganismMenuItem } from '@belong/types';
import kebabCase from 'lodash/kebabCase';
import { GLOBAL_HEADER_TEST_ID as TEST_ID } from '../testids';
import { ARIA_CURRENT } from '../variables';
import * as styles from './styles';
import { GHMobileLink } from './GHMobileLink';
import { IGlobalHeaderMobile } from './types';

interface IMobileMainMenu extends Omit<IGlobalHeaderMobile, 'pageAlert' | 'onExpanded'> {
  handleOffCanvasClose: () => void;
  handleShowLinkPanel: (idx: number) => void;
  currentOrCurrentChild: (menuItem: IOrganismMenuItem) => void;
}

export const GlobalHeaderMobileMainMenu: React.FC<IMobileMainMenu> = ({
  isLoggedIn,
  hideMyAccountLinks,
  currentUrl,
  urls,
  headerMenuContent,
  handleOffCanvasClose,
  handleShowLinkPanel,
  currentOrCurrentChild
}) => {
  return (
    <>
      {isLoggedIn && !hideMyAccountLinks && (
        <>
          {/* My services */}
          {headerMenuContent.myServicesLabel && (
            <GHMobileLink
              aria-current={currentUrl === urls.myServices ? ARIA_CURRENT : undefined}
              data-testid={TEST_ID.BUTTON_MY_SERVICES}
              href={urls.myServices}
              label={headerMenuContent.myServicesLabel}
              onClick={handleOffCanvasClose}
            />
          )}
          {/* My profile */}
          {headerMenuContent.myProfileLabel && (
            <GHMobileLink
              aria-current={currentUrl === urls.myProfile ? ARIA_CURRENT : undefined}
              data-testid={TEST_ID.BUTTON_MY_PROFILE}
              href={urls.myProfile}
              label={headerMenuContent.myProfileLabel}
              onClick={handleOffCanvasClose}
            />
          )}
          <styles.StyledHR />
        </>
      )}
      {headerMenuContent.headerMenuItems.map((menuItem, idx) => {
        const hasNavGroup = menuItem.navGroup1 || menuItem.navGroup2;

        return (
          <GHMobileLink
            key={menuItem.parentLabel}
            aria-current={currentOrCurrentChild(menuItem)}
            data-testid={`${TEST_ID.OFFCANVAS_HEADERLINK_PREFIX}${kebabCase(menuItem.parentLabel).toLowerCase()}`}
            label={menuItem.parentLabel}
            onClick={
              menuItem.navGroup1 || menuItem.navGroup2 ? () => handleShowLinkPanel(idx) : () => handleOffCanvasClose()
            }
            href={menuItem.url ? menuItem.url.href : undefined}
            iconName={hasNavGroup ? ICON_NAMES.ChevronRight : undefined}
            iconRight={!!hasNavGroup}
          />
        );
      })}
    </>
  );
};

GlobalHeaderMobileMainMenu.displayName = 'GlobalHeaderMobileMainMenu';
