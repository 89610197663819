import styled, { css } from 'styled-components';

import { FONT_WEIGHT, FONT_COLOURS, media, textShimmer, getHeadingFont } from '@belong/themes';
import { FontSizeHeadingKey } from '@belong/types';

import { Text } from '../Text';
import { IDisplay } from './Display.types';

// TODO: Eliminate the use of heading level to determine font style. This is a semantic error
const getAsPropFromProps = ({ as, isSmall }: IDisplay): keyof JSX.IntrinsicElements => {
  if (as) {
    return as;
  }

  return isSmall ? 'h2' : 'h1';
};

export const Display = styled(Text).attrs((props: IDisplay) => ({
  hasColor: props.hasColor,
  as: getAsPropFromProps(props)
}))<IDisplay>`
  font-weight: ${FONT_WEIGHT.BOLD};
  overflow-wrap: anywhere;

  ${({ isSmall }) => {
    const headingSize: FontSizeHeadingKey = isSmall ? 'xlarge' : 'xxlarge';
    return css`
      ${getHeadingFont('xs', headingSize)}

      ${media('md')`
        ${getHeadingFont('md', headingSize)}
      `}
    `;
  }}

  ${({ hasColor }) => (hasColor === FONT_COLOURS.LIGHT ? textShimmer : '')};

  color: ${({ hasColor, theme }) =>
    hasColor === FONT_COLOURS.LIGHT
      ? 'transparent'
      : theme.foregroundColorDark}; // use the theme color when present or transparent for shimmer affected
`;
