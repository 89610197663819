import { StyledCSS } from '@belong/types';

import { FONT_COLOURS, isFocused } from '@belong/themes';
import styled, { css } from 'styled-components';
import { withLink, Icon, ButtonUnstyled } from '@belong/ui-components';
import { IGHLargeLinkProps } from '.';

type IButtonStyles = Pick<IGHLargeLinkProps, 'href' | 'secondLevelLink' | 'onClick'>;

const CommonButtonStyles = css<IButtonStyles>`
  display: flex;
  height: 6rem;
  min-height: 6rem; // prevent collapse within flex container
  justify-content: start;
  align-items: center;
  padding-left: 3.2rem;
  padding-right: 1.6rem;
  text-decoration: none;
  user-select: none;

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
      color: white;
    `}

  &:focus {
    ${(): StyledCSS => isFocused(true)}
  }

  &:hover,
  &:focus,
  &&:hover *,
  &&:focus * {
    color: ${FONT_COLOURS.ACTIVE};
  }

  ${props =>
    props.secondLevelLink &&
    css`
      padding-left: 4.8rem;
    `}
`;

export const StyledButton = styled(ButtonUnstyled)<IButtonStyles>`
  ${CommonButtonStyles}
`;

export const StyledIcon = styled(Icon)<{ iconRight: boolean }>`
  margin-right: 0.8rem;
  width: 2.4rem;
  ${({ iconRight }) =>
    iconRight &&
    css`
      position: absolute;
      right: 2.6rem;
      margin-right: 0;
    `}
`;

// Keeping this one at the bottom because it causes the syntax highlighting to be a bit broken
export const Wrapper = withLink(styled.a<IButtonStyles>`
  ${CommonButtonStyles}
`);
