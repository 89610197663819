export const OVERRIDE_COOKIE = 'toggle-overrides';
export const CONSUMER_TAG = 'new-stack-web';

export enum FEATURES {
  TEST_TOGGLE = 'TEST_TOGGLE',

  /**
   * PO Box address lookup
   * https://belongranda.atlassian.net/browse/ACES-78
   */
  ALLOW_PO_BOX_LOOKUP_FEATURE = 'po-box-address-look-up',

  /**
   * Live Chat
   * https://belongranda.atlassian.net/browse/AMF-1368
   */
  LIVE_CHAT = 'live-chat',

  /**
   * Live Chat
   * https://belongranda.atlassian.net/browse/PATH-2424
   */
  LIVE_CHAT_IFRAME = 'live-chat-iframe',

  /**
   * Live Chat as a separate app
   * https://belongranda.atlassian.net/browse/AMF-2442
   */
  LIVE_CHAT_SEPARATE_APP = 'live-chat-separate-app',

  /**
   * Live Chat attachments
   * https://belongranda.atlassian.net/browse/FLEX-82
   */
  LIVE_CHAT_ATTACHMENTS = 'live-chat-attachments',

  /**
   * Live Chat attachments
   * https://belongranda.atlassian.net/browse/FLEX-396
   */
  LIVE_CHAT_SESSIONS_V2 = 'live-chat-sessions-v2',

  /**
   * NBN Manual Address V2 Form for Modem Delivery Page
   * https://belongranda.atlassian.net/browse/FB-992
   */
  NBN_MANUAL_DELIVERY_ADDRESS_V2 = 'nbn-manual-delivery-address-v2',

  /**
   * Uplift for FTTP Service Class 3
   * https://belongranda.atlassian.net/browse/AMF-2450
   */
  SERVICE_TRANSFER_EXTERNAL_GAIN = 'service-transfer-external-gain',

  /**
   * nbn technician appointments
   * https://belongranda.atlassian.net/browse/AMF-140
   */
  NBN_APPOINTMENT = 'nbn-appointment',

  /**
   * Payment assistance application form
   * https://belongranda.atlassian.net/browse/AMF-2753
   */
  PAYMENT_ASSISTANCE = 'payment-assistance',

  /**
   * ID verification
   * https://belongranda.atlassian.net/browse/AMF-2889
   */
  ID_VERIFICATION = 'id-verification-ui',

  /**
   * eSIM
   * https://belongranda.atlassian.net/browse/EF-2
   */
  ESIM = 'esim',

  /**
   * Show the eSIM 'install via app' option
   * https://belongranda.atlassian.net/browse/EF-2
   */
  ESIM_APP_INSTALL = 'esim-app-install',

  /**
   * nbn service transfer
   * https://belongranda.atlassian.net/browse/PLF-65
   * validate avcid as part of the nbn purchase flow
   */

  VALIDATE_AVCID = 'nbn-service-transfer-validate-avcid',

  /**
   * Use activate sim domain, rather than the myaccount version
   * https://belongranda.atlassian.net/browse/FP-311
   */
  ACTIVATE_SIM = 'activate-sim-domain',

  /**
   * Order Summary AB test
   * https://belongranda.atlassian.net/browse/DEF-3
   */
  ORDER_SUMMARY_AB_TEST = 'order-summary-ab-test',

  /**
   * NBN Price rise
   * https://belongranda.atlassian.net/browse/PLN2-533
   */
  NBN_PRICE_RISE = 'nbn-price-rise-ui',

  /**
   * Payment Deferral Alert
   * https://belongranda.atlassian.net/browse/DEE-335
   * https://belongranda.atlassian.net/browse/DEE-336
   * https://belongranda.atlassian.net/browse/DEE-337
   */
  PAYMENT_DEFERRAL = 'payment-deferral',
  /**
   * Partner (agent)
   * https://belongranda.atlassian.net/browse/PLM3-374
   */
  PARTNER_ACCESS_AGENT = 'partner-access-agent',

  /**
   * Fibre Connect Upgrade
   * https://belongranda.atlassian.net/browse/PLN1-486
   */
  FIBRE_CONNECT = 'fibre-connect'
}
